import { message } from 'antd'

import { fetchUtil as fetch } from 'src/utils/fetch'
import { saveAuditData } from 'src/api/audit'

export const getRedeemUsers = async (
  page: number,
  size: number,
  name = '',
  email = '',
  phone = '',
  marketPlaceName = '',
  country = '',
  state = '',
  providerCountry = ''
) => {
  try {
    const requestBody: {
      page: number
      size: number
      providerCountry: string
      searchUserProfileRequest: {
        name: string
        email: string
        phone: string
        country: string
      }
      marketPlaceName: string
      state?: string
    } = {
      page,
      size,
      providerCountry,
      searchUserProfileRequest: {
        name,
        email,
        phone,
        country,
      },
      marketPlaceName,
    }

    if (state !== '') {
      requestBody.state = state
    }

    const { data } = await fetch('/marketplace/users/search', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        role: 'ADMIN',
      },
    })

    return data
  } catch (e) {
    message.error('Search Market Error!')
  }
}

export const saveState = async (record, state) => {
  try {
    const { data } = await fetch(
      `/marketplace/users/state/${record.id}/${state}`,
      {
        method: 'PATCH',
        headers: {
          role: 'ADMIN',
        },
      }
    )
    saveAuditData(
      'EDIT',
      'REDEEMUSER',
      JSON.stringify({
        name: record.name,
        state: state,
      })
    )

    return data
  } catch (e) {
    message.error('Save State Error !')
  }
}
