import { message } from 'antd'
import { omit, pick } from 'ramda'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
import { getImagePath } from 'src/utils/url'
import { saveAuditData } from 'src/api/audit'

export const getAll = async (params) => {
  try {
    const query = buildQuery(params)
    const { data } = await fetch(`/station/searchStation?${query}`)
    return data
  } catch (e) {
    message.error('getAll station error')
  }
}

export const order = async (stationId, newIndex) => {
  try {
    const body = {
      order_id: newIndex,
    }
    return fetch(`/station/${stationId}/order`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
  } catch (e) {
    message.error('order station error')
  }
}

export const update = async (data) => {
  try {
    const response = await fetch(`/station`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
    saveAuditData('EDIT', 'STATION', response.data.name)
    return response
  } catch (error) {
    message.error('update station error')
  }
}

export const create = async (data) => {
  try {
    const response = await fetch(`/station`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
    saveAuditData('CREATE', 'STATION', response.data.name)
    return response
  } catch (e) {
    message.error('create station error')
  }
}

export const getById = async (id) => {
  try {
    const { data } = await fetch(`/station/getStationById/${id}`)
    return data
  } catch (e) {
    message.error('getById station error')
  }
}

export const updateOrCreate = async (
  id: string,
  payload: Record<string, any>
) => {
  try {
    let res = null
    if (id && id !== 'new') {
      let coverUrl = getImagePath(payload.coverUrl)
      if (payload.image?.file) {
        const imageRes = await uploadImage(payload.image?.file)
        coverUrl = imageRes.name
      }
      res = await update({
        coverUrl,
        ...pick(
          ['name', 'type', 'description', 'tags', 'id', 'order_id'],
          payload
        ),
      })
    } else {
      let coverUrl = ''
      if (payload.image?.file) {
        const imageRes = await uploadImage(payload.image?.file)
        coverUrl = imageRes.name
      }
      res = await create({
        coverUrl,
        ...pick(['name', 'type', 'description', 'tags'], payload),
      })
    }
    return res
  } catch (e) {
    message.error('updateOrCreate station error !')
    throw e
  }
}

export const uploadImage = async (file: any) => {
  try {
    const body = new FormData()
    body.append('coverImage', file, file.name)
    const { data } = await fetch(`/station/uploadCoverImage`, {
      method: 'POST',
      body,
    })
    return data
  } catch (e) {
    message.error('uploadImage station error')
  }
}
export const getStationCategories = async (categoryName) => {
  try {
    const response = await fetch(
      `/station/categories/${categoryName}/stations`,
      {
        method: 'GET',
      }
    )

    const { data } = response

    if (data && data.stations) {
      const category = {
        id: data.id,
        name: data.name,
        type: data.type,
        stations: data.stations,
      }

      return [category]
    }

    throw new Error('Invalid response format')
  } catch (error) {
    message.error(`Failed to load stations for category: ${categoryName}`)
    return []
  }
}

export const updateStationsInsideCategory = async (
  category: string,
  categoryType: string,
  stationIds: string[]
) => {
  try {
    const response = await fetch(`/station/categories/${category}`, {
      method: 'POST',
      body: JSON.stringify({ stationIds }),
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData('EDIT', 'DISCOVERY-STATION', categoryType)
  } catch (e) {
    message.error('update Station category error ')
  }
}
export const search = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'size'], params))
  const body = omit(['page', 'size'], params)

  try {
    const query = buildQuery(params)
    const { data } = await fetch(`/station/searchStation?${query}`)
    return data
  } catch (e) {
    message.error('search Station error !')
  }
}
