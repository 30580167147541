import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getAllTriviaCategories, getAllTriviaQuizType } from 'src/api/metaverse';
import AppContext from './AppContext';

interface TriviaQuizCategoryAndType {
  categoryList: string[];
  quizTypeList: string[];
  isLoading: boolean; 
  error: string | null; 
}

const TriviaQuizCategoryAndTypeContext = createContext<TriviaQuizCategoryAndType>({
  categoryList: [],
  quizTypeList: [],
  isLoading: true, 
  error: null,
});

export const useTriviaQuizCategoryAndType = () => { 
  const context = useContext(TriviaQuizCategoryAndTypeContext);
  if (!context) {
    throw new Error('useTriviaQuizCategoryAndType must be used within a TriviaQuizCategoryAndTypeProvider');
  }
  return context;
};

interface TriviaQuizCategoryAndTypeProviderProps {
  children: ReactNode;
}

export const TriviaQuizCategoryAndTypeProvider: React.FC<TriviaQuizCategoryAndTypeProviderProps> = ({ children }) => {
  const appContext = useContext(AppContext);
  const [contextValue, setContextValue] = useState<TriviaQuizCategoryAndType>({
    categoryList: [],
    quizTypeList: [],
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    if (!appContext?.isAuth) { 
      return;
    }

    const fetchData = async () => {
      setContextValue(prevState => ({ ...prevState, isLoading: true, error: null })); 

      try {
        const categories = await getAllTriviaCategories();
        const types = await getAllTriviaQuizType();

        setContextValue({
          categoryList: categories || [], 
          quizTypeList: types || [],
          isLoading: false,
          error: null,
        });
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        setContextValue({
          categoryList: [],
          quizTypeList: [],
          isLoading: false,
          error: error.message || 'Failed to load data.', 
        });
      }
    };

    fetchData();
  }, [appContext?.isAuth]); 

  return (
    <TriviaQuizCategoryAndTypeContext.Provider value={contextValue}>
      {children}
    </TriviaQuizCategoryAndTypeContext.Provider>
  );
};