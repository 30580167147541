import { message } from 'antd'

import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
import { saveAuditData } from 'src/api/audit'

export const getMarketplaceCategory = async () => {
  try {
    const { data } = await fetch('/marketplace/prices/categories')
    return data
  } catch (e) {
    message.error('Get Marketplace Category error!')
  }
}
export const getMonthlyMarketplace = async (country, year, month) => {
  try {
    const { data } = await fetch(
      `/marketplace/prices/monthly/${country}/${year}/${month}`
    )
    return data
  } catch (e) {
    message.error('Get Marketplace Monthly error!')
  }
}

export const getMonthlyWinners = async (country, page, size) => {
  try {
    const { data } = await fetch(
      `/metaverse-rooms/games/monthly/winners/${country}?page=${page}&size=${size}`
    )
    return data
  } catch (e) {
    message.error('No Monthly Winners!')
  }
}

export const saveMonthlyMarketplace = async (
  year,
  month,
  place,
  id,
  country
) => {
  try {
    const { data } = await fetch(`/marketplace/prices/monthly`, {
      method: 'PUT',
      body: JSON.stringify({
        id: id,
        place: place,
        month: month,
        year: year,
        country: country,
      }),
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData(
      'EDIT',
      'MONTHLYMARKETPLACE',
      month + ' ' + year + ' ' + country
    )
    return data
  } catch (e) {
    message.error('Save Marketplace Monthly error!')
  }
}

export const getMarketplace = async (page, size, name = '', country) => {
  try {
    const { data } = await fetch('/marketplace/prices/search', {
      method: 'POST',
      body: JSON.stringify({
        size: size,
        page: page,
        name: name,
        country: country,
      }),
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Search Market Error !')
  }
}

export const saveEditMarketplace = async (
  id,
  name,
  nameFr,
  points,
  price,
  subTitle,
  subTitleFr,
  description,
  descriptionFr,
  enabled,
  category,
  images,
  country
) => {
  try {
    const input = new FormData()
    input.append(
      'marketPlaceDto',
      new Blob(
        [
          JSON.stringify({
            id: id,
            name: name,
            nameFr: nameFr,
            points: points,
            price: price,
            subTitle: subTitle,
            subTitleFr: subTitleFr,
            description: description,
            descriptionFr: descriptionFr,
            enabled: enabled,
            category: category,
            country: country,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        input.append('images', images[i])
      }
    }

    const { data } = await fetch('/marketplace/prices/edit', {
      method: 'POST',
      body: input,
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData('EDIT', 'MARKETPLACE', name)
    message.success('Success Marketplace Edit!')
    return 'success'
  } catch (e) {
    message.error('Edit Marketplace error!')
    return 'failed'
  }
}

export const saveAddMarketplace = async (
  id,
  name,
  nameFr,
  points,
  price,
  subTitle,
  subTitleFr,
  description,
  descriptionFr,
  enabled,
  category,
  images,
  country
) => {
  try {
    const input = new FormData()
    input.append(
      'marketPlaceDto',
      new Blob(
        [
          JSON.stringify({
            id: id,
            name: name,
            nameFr: nameFr,
            points: points,
            price: price,
            subTitle: subTitle,
            subTitleFr: subTitleFr,
            description: description,
            descriptionFr: descriptionFr,
            enabled: enabled,
            category: category,
            country: country,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        input.append('images', images[i])
      }
    }

    const { data } = await fetch('/marketplace/prices/add', {
      method: 'POST',
      body: input,
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData('ADD', 'MARKETPLACE', name)
    message.success('Added Marketplace Successfully!')
    return 'success'
  } catch (e) {
    message.error('Edit Marketplace Add!', e)
    return 'failed'
  }
}
