import React, { useEffect, useCallback } from 'react'
import { Divider, Typography, Spin, message } from 'antd'
import { DndProvider } from 'react-dnd'
import CarouselItem from './carousel-item'
import Carousel from './carousel'
import { HTML5Backend } from 'react-dnd-html5-backend'
import useForceRender from 'src/utils/ForceRender'
import { clone } from 'ramda'
import * as api from 'src/api/category'
import AddingModal from './adding-modal'
import s from './index.module.scss'

const { Title } = Typography

const PlaylistDiscovery: React.FC = () => {
  const forceRender = useForceRender()
  const selectedCategory = React.useRef<Category>(null)
  const showAdding = React.useRef<boolean>(false)
  const loading = React.useRef<boolean>(true)
  const data = React.useRef<any[]>([])

  const loadPageData = useCallback(() => {
    api.getAll().then((categories) => {
      loading.current = false
      data.current = categories
      forceRender()
    })
  }, [])

  useEffect(() => {
    loadPageData()
  }, [])

  const onModalCancel = React.useCallback(() => {
    selectedCategory.current = null
    showAdding.current = false
    forceRender()
  }, [])

  const onAddClick = React.useCallback((category: Category) => {
    selectedCategory.current = category
    showAdding.current = true
    forceRender()
  }, [])

  const onModalDone = React.useCallback(() => {
    onModalCancel()
    loadPageData()
  }, [])

  const updateOrdering = React.useCallback(
    async (dragId: string, dropId: string, category: Category) => {
      const categoryNew = data.current.find(
        (cat: Category) => cat.id === category.id
      ) as Category

      const dragIndex = categoryNew.playlists.findIndex((i) => i.id === dragId)

      const dropIdIndex = categoryNew.playlists.findIndex(
        (i) => i.id === dropId
      )

      let dropIndex: number

      if (dropIdIndex === 0) {
        dropIndex = dropIdIndex
      } else if (dragIndex > dropIdIndex) {
        dropIndex = dropIdIndex
      } else {
        dropIndex = dropIdIndex + 1
      }

      const dragItem = categoryNew.playlists[dragIndex]
      const tempItem = { id: 'temp' } as any
      const cloneItems = clone(categoryNew.playlists)
      cloneItems[dragIndex] = tempItem
      cloneItems.splice(dropIndex, 0, dragItem)
      const orderedItems = cloneItems.filter((i) => i.id !== 'temp')
      const playlistIds = orderedItems.map((i) => i.id)
      loading.current = true
      forceRender()
      await api.updatePlaylists(categoryNew.id, categoryNew.type, playlistIds)
      message.success(`Updated playlist ordering of ${categoryNew.name}`)
      loadPageData()
    },
    [data]
  )

  const handleRemoving = React.useCallback(
    async (playlist: Playlist, category: Category) => {
      loading.current = true
      forceRender()
      const playlistIds = category.playlists?.reduce((IDs, current) => {
        if (current.id !== playlist.id) {
          IDs.push(current.id)
        }
        return IDs
      }, [])
      await api.updatePlaylists(category.id, category.type, playlistIds)
      message.success(`${playlist.name} is removed from ${category.name}`)
      loadPageData()
    },
    []
  )

  return (
    <Spin spinning={loading.current}>
      <Title level={3}>Playlists</Title>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        {data.current?.map((category) => (
          <Carousel
            key={category.id}
            name={category.name}
            onAddClick={() => onAddClick(category)}
          >
            {category.playlists?.length ? (
              category.playlists.map((item) => (
                <CarouselItem
                  key={item.id}
                  playlist={item}
                  categoryId={category.id}
                  onDrop={(drapId, dropId) =>
                    updateOrdering(drapId, dropId, category)
                  }
                  onRemove={(playlist) => handleRemoving(playlist, category)}
                />
              ))
            ) : (
              <span className={s.noData}> No data</span>
            )}
          </Carousel>
        ))}
      </DndProvider>
      <AddingModal
        visible={showAdding.current}
        category={selectedCategory.current}
        onDone={onModalDone}
        onCancel={onModalCancel}
      />
    </Spin>
  )
}

PlaylistDiscovery.displayName = 'Discovery'

export default PlaylistDiscovery
