import { message } from 'antd'
import { pick } from 'ramda'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'

const supportedTypes = [
  'country',
  'deviceType',
  'gender',
  'filterSkipped',
  'maxAge',
  'minAge',
  'providerCountry',
  'userName',
  'metaverseRoomId',
  'gameId',
  'startDate',
  'endDate',
  'groupBy',
  'gameCategory'
]

const filterParamsBySupportedTypes = (
  params: Record<string, any>
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(params).filter(([key]) => supportedTypes.includes(key))
  )
}

const getTodayDateString = () => {
  const today = new Date()
  const yyyy = today.getFullYear()
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const dd = String(today.getDate()).padStart(2, '0')
  return `${dd}/${mm}/${yyyy}`
}

export const searchPlaylistReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    const { data } = await fetch(`/report/playlist-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })

    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/playlist-report/generate/pdf`, {
        method: 'POST',
        filename: `PlaylistReport_${getTodayDateString()}.pdf`,
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/playlist-report/generate/csv`, {
        method: 'POST',
        filename: `PlaylistReport_${getTodayDateString()}.csv`,
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
        contentType: 'text/csv',
      })
    }

    return data
  } catch (e) {
    message.error('search playlist Report error !')
  }
}

export const searchTracksReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))
  const filteredParams = filterParamsBySupportedTypes(params)

  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/tracks-report/generate/pdf`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `TrackReport_${getTodayDateString()}.pdf`,
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/tracks-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `TrackReport_${getTodayDateString()}.csv`,
        responseType: 'blob',
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/tracks-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search tracks Report error !')
  }
}

export const searchStationReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/station-report/generate/pdf`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `StationReport_${getTodayDateString()}.pdf`,
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/station-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `StationReport_${getTodayDateString()}.csv`,
        responseType: 'blob',
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/station-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search Station Report error !')
  }
}
export const searchAppUsageReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/app-usage-report/generate/pdf`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `AppUsageReport_${getTodayDateString()}.pdf`,
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/app-usage-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `AppUsageReport_${getTodayDateString()}.csv`,
        responseType: 'blob',
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/app-usage-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search App Usage Report error !')
  }
}
export const searchMetaverseReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/metaverse-report/generate/pdf`, {
        method: 'POST',
        filename: `MetaverseReport_${getTodayDateString()}.pdf`,
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/metaverse-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `MetaverseReport_${getTodayDateString()}.csv`,
        responseType: 'blob',
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/metaverse-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search metaverse Report error !')
  }
}

export const searchMazeReport = async (params: Record<string, any>) => {
  const queryParam = pick(['page', 'pageSize'], params)
  if (queryParam['pageSize'] === undefined) {
    queryParam['size'] = 10
  } else {
    queryParam['size'] = queryParam['pageSize']
  }
  const query = buildQuery(queryParam)
  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/maze-report/generate/pdf`, {
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
        filename: `MazeUsageReport_${getTodayDateString()}.pdf`,
        method: 'POST',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/maze-report/generate/csv`, {
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
        filename: `MazeUsageReport_${getTodayDateString()}.csv`,
        method: 'POST',
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/maze-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search maze Report error !')
  }
}

export const searchUserQueriesReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/user-queries-report/generate/pdf`, {
        method: 'POST',
        filename: `UserQueriesReport_${getTodayDateString()}.pdf`,
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/user-queries-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `UserQueriesReport_${getTodayDateString()}.csv`,
        responseType: 'blob',
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(
      `/report/user-queries-report/search?${query}`,
      {
        method: 'POST',
        body: JSON.stringify(filteredParams),
      }
    )
    return data
  } catch (e) {
    message.error('search User Queries Report error !')
  }
}
export const searchUserProfileChangeReport = async (
  params: Record<string, any>
) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(
        `/report/profile-change-report/generate/pdf`,
        {
          method: 'POST',
          filename: `ProfileChangeReport_${getTodayDateString()}.pdf`,
          body: JSON.stringify(filteredParams),
          responseType: 'blob',
        }
      )
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(
        `/report/profile-change-report/generate/csv`,
        {
          method: 'POST',
          body: JSON.stringify(filteredParams),
          filename: `ProfileChangeReport_${getTodayDateString()}.csv`,
          responseType: 'blob',
          contentType: 'text/csv',
        }
      )
    }

    const { data } = await fetch(
      `/report/profile-change-report/search?${query}`,
      {
        method: 'POST',
        body: JSON.stringify(filteredParams),
      }
    )
    return data
  } catch (e) {
    message.error('search User Profile Change Report error !')
  }
}
export const searchGameReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/game-usage-report/generate/pdf`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `GameUsageReport_${getTodayDateString()}.pdf`,
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/game-usage-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
        filename: `GameUsageReport_${getTodayDateString()}.csv`,
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/game-usage-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search Game Usage Report error !')
  }
}
export const getListOfGames = async () => {
  try {
    const { data } = await fetch('/report/game-usage-report/games')
    return data
  } catch (e) {
    message.error('Get List of Gams error!')
  }
}
export const searchChatUsageReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/chat-usage-report/generate/pdf`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `ChatUsageReport_${getTodayDateString()}.pdf`,
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/chat-usage-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `ChatUsageReport_${getTodayDateString()}.csv`,
        responseType: 'blob',
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/chat-usage-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search Chat Usage Report error !')
  }
}

export const searchSocialUsageReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/social-usage-report/generate/pdf`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `SocialUsageReport_${getTodayDateString()}.pdf`,
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/social-usage-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `SocialUsageReport_${getTodayDateString()}.csv`,
        responseType: 'blob',
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/social-usage-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search Social Usage Report error !')
  }
}
export const searchTreasureHunterReport = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/treasure-hunter-report/generate/pdf`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `TreasureHunterReport_${getTodayDateString()}.pdf`,
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/treasure-hunter-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
        filename: `TreasureHunterReport_${getTodayDateString()}.csv`,
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/treasure-hunter-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search Treasure Hunter Report error !')
  }
}
export const searchSurvivorZoneReport = async (params: Record<string, any>) => {
  params.gameCategory = ["EXSCAPEGUYS"];
  const query = buildQuery(pick(['page', 'pageSize'], params))

  const filteredParams = filterParamsBySupportedTypes(params)
  try {
    if (params.buttonAction === 'Generate Pdf') {
      const response = await fetch(`/report/game-usage-report/generate/pdf`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        filename: `SurvivorZoneReport_${getTodayDateString()}.pdf`,
        responseType: 'blob',
      })
    } else if (params.buttonAction === 'Generate Csv') {
      const response = await fetch(`/report/game-usage-report/generate/csv`, {
        method: 'POST',
        body: JSON.stringify(filteredParams),
        responseType: 'blob',
        filename: `SurvivorZoneReport_${getTodayDateString()}.csv`,
        contentType: 'text/csv',
      })
    }

    const { data } = await fetch(`/report/game-usage-report/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(filteredParams),
    })
    return data
  } catch (e) {
    message.error('search Survivor Zone Report error !')
  }
}