import { useState } from 'react'
import { Card, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import './Metaversecard.css'
import { useNavigate } from 'react-router-dom'

const MetaverseCard = ({
  id,
  name,
  img,
  thumbImg,
  title,
  titleFr,
  desc,
  accessLevels,
}) => {
  const navigate = useNavigate()
  const params = new URLSearchParams()
  params.append('img', img)
  params.append('thumbImg', thumbImg)
  params.append('title', title)
  params.append('titleFr', titleFr)
  params.append('desc', desc)
  params.append('name', name)

  accessLevels.forEach((accessLevel) =>
    params.append('accessLevels', accessLevel)
  )

  const [dataLoaded] = useState(true)
  const handleClickOpen = () => {
    navigate(`/metaverse/edit/${id}?${params.toString()}`)
  }

  return (
    <div>
      {dataLoaded ? (
        <Card
          hoverable
          className="card-container"
          cover={
            <div className="card-cover">
              <img alt={title} src={img} className="card-image" />
              <EditOutlined onClick={handleClickOpen} className="edit-icon" />
            </div>
          }
        >
          <Card.Meta title={title} description={desc} />
        </Card>
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default MetaverseCard
