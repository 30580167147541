import {
  Divider,
  Table,
  Spin,
  Button,
  Input,
  message,
  Modal,
  Switch,
} from 'antd'
import { useEffect, useState, useCallback } from 'react'
import { getUserComment, deleteCommentById } from 'src/api/social'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { search } from 'src/api/user'
import './Usercomment.css'
import { extractTextContent } from 'src/utils/stringUtil'

const { Search } = Input
const { confirm } = Modal

const UserComments = () => {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(undefined)
  const [transformedData, setTransformedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [badContent, setBadContent] = useState(false)

  const transformData = async (data) => {
    if (data == null) {
      return null
    }
    return await Promise.all(
      data.map(async (item) => {
        return {
          ...item,
          key: item.id,
          username: item.user.username,
          createdOn: convertToCustomFormat(item.created_on),
        }
      })
    )
  }

  const handleToggleBadContent = (checked) => {
    setBadContent(checked)
    setCurrentPage(1)
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure you want to delete this comment?',
      content: 'Once deleted, this action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk: () => handleDelete(record),
      onCancel: () => {
        message.info('Deletion cancelled.')
      },
    })
  }

  const handleDelete = async (record) => {
    try {
      setLoading(true)
      await deleteCommentById(record)
      message.success('Comment deleted successfully!')
      fetchUserCommentData(currentPage, pageSize, username)
    } catch (error) {
      message.error('Failed to delete Comment.')
    }
  }

  const handleTableChange = (pagination: any) => {
    const { current, pageSize } = pagination
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
    fetchUserCommentData(current, pageSize, username)
  }

  const fetchUserCommentData = async (page, size, userId) => {
    try {
      setLoading(true)
      const response = await getUserComment(page - 1, size, userId, badContent)
      const transformedData = await transformData(response.data)
      setTransformedData(transformedData)
      setTotalRecords(response.total_items)
    } finally {
      setLoading(false)
    }
  }

  const convertToCustomFormat = (timestamp) => {
    const date = new Date(timestamp)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
  }

  const handleSearch = async (value) => {
    if (!value) {
      setUsername('')
      setCurrentPage(1)
      setPageSize(10)
      fetchUserCommentData(1, 10, '')
      return
    }

    const users = await findUser(value)

    if (users.length > 0) {
      const matchedUser = users.find((u) => u.username === value)
      const userId = matchedUser ? matchedUser.user_id : users[0].user_id
      setUsername(userId)
      setCurrentPage(1)
      setPageSize(10)
      fetchUserCommentData(1, 10, userId)
    } else {
      setUsername('')
      setCurrentPage(1)
      setPageSize(10)
      fetchUserCommentData(1, 10, '')
    }
  }

  const findUser = useCallback(async (value) => {
    if (!value) return
    setLoading(true)
    const res = await search({ username: value, size: 50 })
    setLoading(false)
    return res.data
  }, [])

  useEffect(() => {
    fetchUserCommentData(currentPage, pageSize, username || '')
  }, [pageSize, currentPage, username, badContent])

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      width: 300,
      render: (text) => extractTextContent(text),
    },
    {
      title: 'Date',
      dataIndex: 'createdOn',
      key: 'createdOn',
      width: 200,
    },
    {
      title: '',
      key: 'delete',
      render: (_, record) => (
        <Button type="primary" danger onClick={() => showDeleteConfirm(record)}>
          Delete
        </Button>
      ),
    },
  ]

  return (
    <>
      <Spin spinning={loading}>
        <Search
          placeholder="Search by username"
          onSearch={handleSearch}
          style={{ width: '16.5%', marginLeft: '15px' }}
          allowClear
        />
        <Switch
          checked={badContent}
          onChange={handleToggleBadContent}
          style={{ marginLeft: '15px' }}
          checkedChildren="Bad Content"
          unCheckedChildren="All Comments"
        />
        <Divider />
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={transformedData}
            columns={columns}
            rowClassName={(record) =>
              record.text_bad_content ? 'bad-content-row' : ''
            }
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50', '100'],
              total: totalRecords,
            }}
            onChange={handleTableChange}
          />
        </DndProvider>
      </Spin>
    </>
  )
}

export default UserComments
