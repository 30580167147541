import { useState } from 'react'
import { Input, Divider } from 'antd'
import SectionMaze from './sectionmaze'
import './Mazeindex.css'

const { Search } = Input

const Maze = () => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  return (
    <div>
      <Search
        placeholder="Search by name"
        onSearch={handleSearch}
        style={{ width: '16.5%' }}
        allowClear
      />
      <Divider style={{ margin: '10px 0' }} />
      <div className="sections-container">
        <SectionMaze searchTerm={searchTerm} />
      </div>
    </div>
  )
}

Maze.displayName = 'Maze'

export default Maze
