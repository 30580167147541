import React, { useEffect, useState } from "react";
import { Modal, Input, Select, Button, Radio, Checkbox, Space, message } from "antd";
import {useTriviaQuizCategoryAndType} from 'src/hooks/TriviaQuizCategoryAndTypeContext'
import { v4 as uuidv4 } from 'uuid';
interface Answer {
  id?: string;
  text: string;
}

interface Question {
  text: string;
  type: "SINGLE_CHOICE" | "MULTIPLE_CHOICE" | "TRUE_FALSE";
  category?: string;
  answers?: Answer[];
  correctAnswerIds?: string[];
  correctAnswer?: boolean;
}

interface Props {
  question:Question|null
  visible: boolean;
  onCancel: () => void;
  onSave: (newQuestion: Question) => void;
}
const initialQuestionState: Question = {
    text: "",
    type: "SINGLE_CHOICE",
    category: "",
    answers: [],
    correctAnswerIds: [],
    
};
const EditQuizQuestion: React.FC<Props> = ({question, visible, onCancel, onSave }) => {
    const { categoryList, quizTypeList, isLoading, error } =useTriviaQuizCategoryAndType()
    const [editedQuestion, setEditedQuestion] = useState<Question | null>(question);

    useEffect(() => {
      setEditedQuestion(question)
    },[question,onCancel]);
  const handleInputChange = (field: keyof Question, value: any) => {
    setEditedQuestion(prev => ({ ...prev, [field]: value }));
  };

  const addAnswer = () => {
    setEditedQuestion(prev => ({
      ...prev,
      answers: [...(prev.answers || []), { text: "" }],
    }));
  };

  const removeAnswer = (index: number) => {
    setEditedQuestion(prev => {
      const updatedAnswers = [...(prev.answers || [])];
      const removedAnswer = updatedAnswers.splice(index, 1)[0];

      const updatedCorrectAnswerIds = (prev.correctAnswerIds || []).filter(
          id => id !== removedAnswer?.id
      );

      return { ...prev, answers: updatedAnswers, correctAnswerIds: updatedCorrectAnswerIds };
    });
  };

  const updateAnswerText = (index: number, text: string) => {
    setEditedQuestion(prev => {
      const updatedAnswers = [...(prev.answers || [])];
      updatedAnswers[index].text = text;
     
      return { ...prev, answers: updatedAnswers };
    });
  };

  const handleCorrectAnswerChange = (value: string | string[]) => {
    if (editedQuestion.type === "MULTIPLE_CHOICE" ) {
      console.log('value',value)
      setEditedQuestion(prev => ({ ...prev, correctAnswerIds: value as string[] }));
    } else {
      setEditedQuestion(prev => ({ ...prev, correctAnswerIds: [value as string] }));
    }
  };

  const handleSubmit = () => {
    console.log('save called')
    onSave(editedQuestion);
    onCancel()
  };
  const handleCancel =() => {
    
    onCancel()

  }

  return (
    <Modal
      visible={visible}
      title="Edit Question"
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText="Save"
      cancelText="Cancel"
    >
      <Input.TextArea
        value={editedQuestion?.text}
        onChange={e => handleInputChange("text", e.target.value)}
        placeholder="Enter question text"
        rows={2}
      />

      <Select
        value={editedQuestion?.category}
        onChange={value => handleInputChange("category", value)}
        style={{ width: "100%", marginTop: 10 }}
        placeholder="Select category"
      >
        {categoryList.map(category => (
          <Select.Option key={category} value={category}>
            {category}
          </Select.Option>
        ))}
      </Select>

      {editedQuestion?.type !== "TRUE_FALSE" && (
        <>
          <h4 style={{ marginTop: 10 }}>Answers</h4>
          {editedQuestion?.answers?.map((answer, index) => (
            <Space key={index} style={{ width: "100%", display: "flex", marginBottom: 5 }}>
              <Input
                value={answer.text}
                onChange={e => updateAnswerText(index, e.target.value)}
                placeholder={`Answer ${index + 1}`}
                style={{ flex: 1 }}
              />
              <Button danger onClick={() => removeAnswer(index)}>Remove</Button>
            </Space>
          ))}
          <Button type="dashed" onClick={addAnswer} style={{ marginTop: 5 }}>
            Add Answer
          </Button>
        </>
      )}

      <h4 style={{ marginTop: 10 }}>Correct Answer</h4>
      {editedQuestion?.type === "MULTIPLE_CHOICE" && (
        
        <Checkbox.Group  value={Array.isArray(editedQuestion.correctAnswerIds) ? editedQuestion.correctAnswerIds : []} 
        onChange={(checkedValues: string[]) => { 
            handleCorrectAnswerChange(checkedValues); 
        }}>
            <Space direction="horizontal">
            {editedQuestion?.answers?.map(ans => (
              <Checkbox key={ans.text} value={ans.id}>
                {ans.text}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
        
      )}
      {editedQuestion?.type === "SINGLE_CHOICE" && (
        <Radio.Group value={editedQuestion.correctAnswerIds?.[0]}
        onChange={e => handleCorrectAnswerChange(e.target.value)}>
          <Space direction="horizontal">
            {editedQuestion?.answers?.map(ans => (
              <Radio key={ans.text} value={ans.id}>
                {ans.text}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )}
      {editedQuestion?.type === "TRUE_FALSE" && (
        <Radio.Group value={editedQuestion.correctAnswer}
        onChange={e => handleInputChange("correctAnswer", e.target.value)}>
          <Space direction="horizontal">
            <Radio value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Space>
        </Radio.Group>
      )}
    </Modal>
  );
};

export default EditQuizQuestion;
