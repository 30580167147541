import { message } from 'antd'

import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
import { saveAuditData } from 'src/api/audit'

export const get = async () => {
  try {
    const { data } = await fetch('/user-profile/abuseReport/reasons', {
      headers: {
        role: 'ADMIN',
      },
    })

    return data
  } catch (e) {
    message.error('Get Abuse Reason error!')
  }
}

export const deleteReason = async (item) => {
  try {
    const { data } = await fetch(`/user-profile/abuseReport/${item.id}`, {
      method: 'DELETE',
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData('DELETE', 'ABUSE', item.title)
    return 'success'
  } catch (e) {
    message.error('Delete Abuse Reason error!')
    return 'failure'
  }
}

export const save = async ({ id, title, description }) => {
  try {
    const { data } = await fetch('/user-profile/abuseReport/save', {
      method: 'POST',
      body: JSON.stringify({
        title: title,
        description: description,
        id: id,
      }),
      headers: {
        role: 'ADMIN',
      },
    })
    if (id != null) saveAuditData('EDIT', 'ABUSE', title)
    else saveAuditData('ADD', 'ABUSE', title)
    return 'success'
  } catch (e) {
    message.error('Save Abuse Reason error!')
    return 'failure'
  }
}
