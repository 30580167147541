import React, { useState } from "react";
import { Modal, Input, Select, Button, Radio, Checkbox, Space, message } from "antd";
import {useTriviaQuizCategoryAndType} from 'src/hooks/TriviaQuizCategoryAndTypeContext'

interface Props {
  visible: boolean;
  onCancel: () => void;
  onCreate: (newQuestion: SearchQuestion) => Promise<boolean>;
}
const initialQuestionState: SearchQuestion = {
    text: "",
    type: null,
    category: "",
    
};
const SearchQuestion: React.FC<Props> = ({ visible, onCancel, onCreate }) => {
    const { categoryList, quizTypeList, isLoading, error } =useTriviaQuizCategoryAndType()
  const [question, setQuestion] = useState<SearchQuestion>({
    text: "",
    type: null,
    category: "",
  });

  const handleInputChange = (field: keyof SearchQuestion, value: any) => {
    setQuestion(prev => ({ ...prev, [field]: value }));
  };


  const handleSubmit = () => {
   
    onCreate(question).then(success => { 
      
      if (success) {
        setQuestion(initialQuestionState);
      }
    });
    
  };
  const handleCancel =() => {
   setQuestion(initialQuestionState)
    
    onCancel()
  }

  return (
    <Modal
      visible={visible}
      title="Search Question"
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText="Search"
      cancelText="Cancel"
    >
      <Input.TextArea
        value={question.text}
        onChange={e => handleInputChange("text", e.target.value)}
        placeholder="Enter question text"
        rows={2}
      />

      <Select
        value={question.category || null} 
        onChange={value => handleInputChange("category", value)}
        style={{ width: "100%", marginTop: 10 }}
        placeholder="Select category"
      >
        {categoryList.map(category => (
          <Select.Option key={category} value={category}>
            {category}
          </Select.Option>
        ))}
      </Select>

    </Modal>
  );
};

export default SearchQuestion;
