import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { getByCategoriesAll } from 'src/api/game'
import AppContext from './AppContext';

const GamesCategoryContext = createContext<{
  categoryMap: Map<string, Set<string>> | null;
  filterGamesByType: (type: string | undefined) => string[];
  findCategoriesForGame: (gameId: string) => string[];
} | null>(null);


export const useCategoryMap = () => {
  const context = useContext(GamesCategoryContext)
  if (!context) {
    throw new Error('useCategoryMap must be used within a CategoryMapProvider')
  }
  return context
}

interface CategoryMapProviderProps {
  children: ReactNode 
}


export const CategoryMapProvider: React.FC<CategoryMapProviderProps> = ({ children }) => {
  const appContext = useContext(AppContext); 
  const [categoryMap, setCategoryMap] = useState<Map<string, Set<string>>>(new Map())

  useEffect(() => {
     
     if (!appContext || !appContext.isAuth) {
      console.warn('CategoryMapProvider: Token is not available');
      return;
    }
    const fetchCategoryMap = async () => {
      try {
        const data = await getByCategoriesAll()
        if (data) {
          const map = new Map<string, Set<string>>()
          data.forEach((category) => {
            if (category.type && Array.isArray(category.games)) {
              category.games.forEach((game) => {
                if (!map.has(game.id)) {
                  map.set(game.id, new Set())
                }
                map.get(game.id)?.add(category.type)
              })
            }
          })
          setCategoryMap(map)
        }
      } catch (error) {
        console.error('Error fetching game category:', error)
      }
    }

    fetchCategoryMap()
  }, [appContext.isAuth]);

 
  const filterGamesByType = (type: string | undefined): string[] => {
    const filteredGames: string[] = []
    categoryMap?.forEach((categories, gameId) => {
      if (categories.has(type?.toUpperCase())) {
        filteredGames.push(gameId)
      }
    })
    return filteredGames
  }

 
  const findCategoriesForGame = (gameId: string): string[] => {
    const result: string[] = []
    if (categoryMap?.has(gameId)) {
      result.push(...Array.from(categoryMap.get(gameId)!))
    }
    return result
  }

  return (
    <GamesCategoryContext.Provider
      value={{
        categoryMap,
        filterGamesByType,
        findCategoriesForGame,
      }}
    >
      {children}
    </GamesCategoryContext.Provider>
  )
}
