import { Button, Divider, Modal, Table, Tag } from 'antd'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PrivateImage from 'src/components/PrivateImage'
import DragTableRow from 'src/components/DragTableRow'
import TagColumn from './TagColumn'
import usePage from 'src/hooks/usePage'
import { getAll, order, remove, search } from 'src/api/playlist'
import PlaylistSearch from './search'

const { Column } = Table

const PlaylistPage: React.FC = () => {
  const { pageData, onQuery, appendSearchForm, submitSearchForm, fetchList } =
    usePage({
      getData: getAll,
      searchData: search,
      searchType: 'POST',
    })

  const removePlaylist = useCallback(
    async (data) => {
      await remove(data)
      fetchList()
    },
    [pageData.data]
  )

  const moveRow = useCallback(
    async (dragIndex, dropIndex) => {
      const playlistId = pageData.data[dragIndex].id
      const newOrderId = pageData.data[dropIndex]?.order_id || dropIndex
      await order(playlistId, newOrderId)
      fetchList()
    },
    [pageData.data]
  )

  const onDelete = (data) => {
    Modal.confirm({
      title: (
        <span>
          You&apos;re deleting the playlist <strong>{data.name}</strong>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk: () => removePlaylist(data),
    })
  }

  return (
    <>
      <Button type="primary">
        <Link to="/playlist/new">Create</Link>
      </Button>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        <Table
          className="table-sorting"
          rowKey="id"
          dataSource={pageData.data}
          loading={pageData.loading}
          pagination={{
            current: parseInt(pageData.query.page) + 1,
            total: pageData.total,
            onChange: (p) => onQuery({ page: (p - 1).toString() }),
          }}
          title={() => (
            <PlaylistSearch
              appendSearchForm={appendSearchForm}
              submitSearchForm={submitSearchForm}
            />
          )}
          components={{
            body: {
              row: DragTableRow,
            },
          }}
          onRow={(_, index) =>
            ({
              index,
              moveRow,
            } as any)
          }
        >
          <Column
            width={120}
            dataIndex="cover_url"
            title="Cover Image"
            render={(img) => (
              <PrivateImage width={90} height={90} src={img} preview={false} />
            )}
          />
          <Column
            dataIndex="name"
            title="Name"
            render={(data, row: any) => (
              <Link to={`/playlist/${row.id}`}>{data}</Link>
            )}
          />
          <Column dataIndex="number_tracks" title="Tracks" />
          <Column
            dataIndex="privacy"
            title="Privacy"
            render={(data) => (
              <Tag color={data === 'PRIVATE' ? 'blue' : 'green'}>{data}</Tag>
            )}
            filters={[
              {
                text: 'PUBLIC',
                value: 'PUBLIC',
              },
              {
                text: 'PRIVATE',
                value: 'PRIVATE',
              },
            ]}
            filterMode="tree"
            onFilter={(value: string, record: any) => record.privacy === value}
          />
          <Column
            width={500}
            dataIndex="tags"
            title="Tags"
            render={(_, row: any) => (
              <TagColumn id={row.id} name={row.name} tags={row.tags} />
            )}
          />
          <Column
            dataIndex="modified_on"
            title="Last date changed"
            render={(data) => moment(data).format('DD/MM/YYYY hh:mm:ss')}
          />
          <Column
            width={90}
            title=""
            dataIndex=""
            render={(data, row) => (
              <Button type="primary" danger onClick={() => onDelete(row)}>
                Delete
              </Button>
            )}
          />
        </Table>
      </DndProvider>
    </>
  )
}

PlaylistPage.displayName = 'PlaylistPage'
export default PlaylistPage
