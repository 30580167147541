import { useState, useEffect } from 'react'
import { Divider, Table, Select, Tooltip } from 'antd'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { getRedeemUsers, saveState } from 'src/api/redeemusers'
import { STATESOPTIONS } from 'src/constants'
import { fetchCountryProvider } from 'src/api/authen'

const { Column } = Table

import RedeemUserSearch from './search'

interface Marketplace {
  name: string
  id: string
}

interface UserRecord {
  id: string
  name: string
  email: string
  phone: string
  marketplace?: Marketplace
  redeemedPoints: number
  state: string
  country: string
}

const RedeemUsers = () => {
  const [providerCountry, setProviderCountry] = useState('TN')
  const [pageData, setPageData] = useState<{ data: UserRecord[] }>({ data: [] })
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchParams, setSearchParams] = useState({
    name: '',
    phone: '',
    email: '',
    marketPlaceName: '',
    country: '',
    state: '',
  })

  const [countries, setCountries] = useState([])

  const fetchData = async (
    page: number,
    size: number,
    providedCountry,
    searchParams
  ) => {
    setLoading(true)
    try {
      const response = await getRedeemUsers(
        page - 1,
        size,
        searchParams.name,
        searchParams.email,
        searchParams.phone,
        searchParams.marketPlaceName,
        searchParams.country,
        searchParams.state,
        providedCountry
      )
      setPageData({ data: response.data })
      setTotalRecords(response.total_items)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(currentPage, pageSize, providerCountry, searchParams)
  }, [currentPage, pageSize])

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const data = await fetchCountryProvider()
        const countryOptions = data.data.map((item) => ({
          label: item.countryName,
          value: item.countryCode,
        }))
        setCountries(countryOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchCountry()
  }, [])

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const appendSearchForm = (updatedParams: any) => {
    setCurrentPage(1)
    setSearchParams((prevParams) => ({ ...prevParams, ...updatedParams }))
  }

  const submitSearchForm = () => {
    setCurrentPage(1)
    fetchData(1, pageSize, providerCountry, searchParams)
  }

  const handleProviderCountryChange = (value) => {
    setCurrentPage(1)
    setSearchParams({
      name: '',
      phone: '',
      email: '',
      marketPlaceName: '',
      state: '',
      country: '',
    })
    setProviderCountry(value)
    fetchData(1, pageSize, value, {
      name: '',
      phone: '',
      email: '',
      marketPlaceName: '',
      state: '',
      country: '',
    })
  }

  const handleStateChange = async (value: string, record: UserRecord) => {
    setLoading(true)
    try {
      await saveState(record, value)
      submitSearchForm()
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Select
        placeholder="Select Country"
        value={providerCountry}
        onChange={handleProviderCountryChange}
        options={countries}
        style={{ marginLeft: '15px' }}
      />
      <Divider />
      <DndProvider backend={HTML5Backend}>
        <Table<UserRecord>
          className="table-sorting"
          rowKey="id"
          dataSource={pageData.data}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            total: totalRecords,
          }}
          onChange={handleTableChange}
          title={() => (
            <RedeemUserSearch
              appendSearchForm={appendSearchForm}
              submitSearchForm={submitSearchForm}
              resetFields={true}
              searchParams={searchParams}
            />
          )}
        >
          <Column<UserRecord>
            dataIndex="createdDate"
            title="Create Date"
            render={(name: string) => (
              <Tooltip title={name}>
                <span>
                  {name.length > 10 ? `${name.substring(0, 10)}...` : name}
                </span>
              </Tooltip>
            )}
          />
          <Column<UserRecord> dataIndex="name" title="Name" />
          <Column<UserRecord> dataIndex="phone" title="Phone" />
          <Column<UserRecord> dataIndex="email" title="Email" />
          <Column<UserRecord>
            dataIndex="address"
            title="Address"
            render={(name: string) => (
              <Tooltip title={name}>
                <span>
                  {name.length > 10 ? `${name.substring(0, 10)}...` : name}
                </span>
              </Tooltip>
            )}
          />
          <Column<UserRecord> dataIndex="country" title="Country" />
          <Column<UserRecord>
            title="Marketplace Name"
            render={(record) => {
              if (record.marketPlace && record.marketPlace.name) {
                const marketPlaceName = record.marketPlace.name
                return (
                  <Tooltip title={marketPlaceName}>
                    <span>
                      {marketPlaceName.length > 10
                        ? `${marketPlaceName.substring(0, 10)}...`
                        : marketPlaceName}
                    </span>
                  </Tooltip>
                )
              } else return null
            }}
          />
          <Column<UserRecord>
            dataIndex="redeemedPoints"
            title="Redeemed Points"
          />
          <Column<UserRecord>
            title="State"
            dataIndex="state"
            render={(state: string, record: UserRecord) => (
              <Select
                value={state}
                onChange={(value) => handleStateChange(value, record)}
                options={STATESOPTIONS}
              />
            )}
          />
        </Table>
      </DndProvider>
    </>
  )
}

export default RedeemUsers
