import React, { useState } from "react";
import { Card, Radio, Space, Button, message, Modal } from "antd";
import EditQuizQuestion from "./EditQuizQuestion";
import { deleteTriviaQuestionAPI, fetchTriviaQuestionsAPI, updateTriviaQuestion, validateQuestion } from 'src/api/metaverse';

interface Props {
  questions: Question[];
  dispatch: React.Dispatch<any>;
  onUpdate: (newQuestion: Question) => void;
  onDelete: (questionId: string) => void;
}

const SingleAnswerQuiz: React.FC<Props> = ({ questions, dispatch,onUpdate,onDelete }) => {
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false); 

  const handleSave = (updatedQuestion: Question) => {
    
    onUpdate(updatedQuestion);

  };
  const handleCancel = () => {
    
    setSelectedQuestion(null);
    

  };

  const openModal = (question: Question) => {
    setSelectedQuestion(question);
    setIsModalVisible(true); 
  };
  const handleDeleteQuestion = (questionId: string) => {
   
    onDelete(questionId);

  };

  return (
    <div className="question-list">
     {questions.map(question => {
         const selectedAnswer =  question.correctAnswerIds?.[0] || "";
        return (
        <Card
          key={question.id}
          style={{ marginBottom: 20, cursor: "pointer" }}
          onClick={() => openModal(question)}
        >
          <div className="question-card-content">
            <h3>{question.text}</h3>
            <Radio.Group value={selectedAnswer}>
              <Space direction="horizontal">
                {question.answers.map(ans => (
                  <Radio key={ans.id} value={ans.id}>
                    {ans.text}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
           
            <Button
              danger
              onClick={e => {
                e.stopPropagation(); 
                handleDeleteQuestion(question.id);
              }}
              style={{ marginTop: 10 }}
            >
              Remove
            </Button>
          </div>
        </Card>
        );
})}

    
      <EditQuizQuestion
        question={selectedQuestion}
        visible={isModalVisible}
        onSave={handleSave}
        onCancel={() => setIsModalVisible(false)}  
      />
    </div>
  );
};

export default SingleAnswerQuiz;
