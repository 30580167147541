import {
  Select,
  Table,
  Spin,
  Button,
  Input,
  message,
  Modal,
  Tooltip,
} from 'antd'
import { useEffect, useState } from 'react'
import {
  getBotPostComment,
  deleteCommentById,
  editComment,
  replyComment,
  addLike,
  unLike,
} from 'src/api/social'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import EmojiPicker from 'emoji-picker-react'
import { useParams, useLocation } from 'react-router-dom'
import { likeTypes } from 'src/constants'
import {
  ReloadOutlined,
  SmileOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

const { confirm } = Modal

const ViewComment = () => {
  const { id } = useParams()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [botUserId, setBotUserId] = useState(params.get('botUserId'))

  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editRecord, setEditRecord] = useState(null)
  const [editText, setEditText] = useState('')
  const [newAttachments, setNewAttachments] = useState([])
  const [deletedAttachments, setDeletedAttachments] = useState([])
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const [loading, setLoading] = useState(true)

  const [transformedData, setTransformedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalMode, setModalMode] = useState<'edit' | 'reply'>('edit')
  const [modalRecord, setModalRecord] = useState(null)
  const [modalText, setModalText] = useState('')

  const showModal = (record, mode: 'edit' | 'reply') => {
    setModalMode(mode)
    setModalRecord(record)
    setModalText(mode === 'edit' ? record.text : '')
    setNewAttachments([])
    setDeletedAttachments([])
    setModalVisible(true)
  }

  const handleSave = async () => {
    if (!modalRecord) return

    setLoading(true)
    try {
      const updatedData = {
        id: modalRecord.id,
        text: modalText,
        newAttachments,
        attachmentsToDelete: deletedAttachments?.map(
          (attachment) => attachment
        ),
      }

      if (modalMode === 'edit') {
        await postEditComment(updatedData)
      } else {
        await postReply(updatedData)
      }

      setModalVisible(false)
      await fetchBotPostData(currentPage, pageSize, id)
    } catch (error) {
      message.error(
        `Failed to ${modalMode === 'edit' ? 'update post' : 'add reply'}.`
      )
    } finally {
      setLoading(false)
    }
  }

  const postReply = async (data) => {
    data.postId = id
    data.bot_user_id = botUserId
    await replyComment(data)
  }

  const postEditComment = async (data) => {
    data.postId = id
    data.bot_user_id = botUserId
    await editComment(data)
  }

  const transformData = async (data) => {
    if (data == null) {
      return null
    }
    const transformedArray = await Promise.all(
      data.map(async (item) => {
        if (item.deleted != true)
          return {
            ...item,
            key: item.id,
            user_id: item.user.user_id,
          }
      })
    )
    return transformedArray
  }

  const handleDelete = async (record) => {
    try {
      setLoading(true)
      await deleteCommentById(record)
      message.success('Comment deleted successfully!')

      const newTotalRecords = totalRecords - 1
      const newPage =
        currentPage > 1 && newTotalRecords % pageSize === 0
          ? currentPage - 1
          : currentPage

      await fetchBotPostData(newPage, pageSize, id)
      setCurrentPage(newPage)
    } catch (error) {
      message.error('Failed to delete content.')
    } finally {
      setLoading(false)
    }
  }

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const fetchBotPostData = async (page, size, id) => {
    try {
      setLoading(true)
      const response = await getBotPostComment(page - 1, size, id, botUserId)
      const transformedData = await transformData(response.data)
      setTransformedData(transformedData)
      setTotalRecords(response.total_items)
    } finally {
      setLoading(false)
    }
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure you want to delete this content?',
      content: 'Once deleted, this action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk: () => handleDelete(record),
      onCancel: () => {
        message.info('Deletion cancelled.')
      },
    })
  }

  useEffect(() => {
    fetchBotPostData(currentPage, pageSize, id)
  }, [currentPage, pageSize])

  const handleEmojiSelect = (emojiObject) => {
    const textarea = document.getElementById(
      'modalTextArea'
    ) as HTMLTextAreaElement
    if (!textarea) return

    const start = textarea.selectionStart
    const end = textarea.selectionEnd

    setModalText((modalText) => {
      const textBefore = modalText.slice(0, start)
      const textAfter = modalText.slice(end)

      return textBefore + emojiObject.emoji + textAfter
    })
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd =
        start + emojiObject.emoji.length
      textarea.focus()
    }, 0)
  }

  const handleInputChange = async (value, record) => {
    record.botUserId = botUserId
    record.reaction = value
    if (value === undefined) {
      await unLike(record)
    } else {
      await addLike(record)
    }
    fetchBotPostData(currentPage, pageSize, id)
  }

  const columns = [
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      width: 800,
      render: (text) => (
        <Tooltip title={text}>
          {text?.length > 80 ? text.substring(0, 80) + '...' : text}
        </Tooltip>
      ),
    },
    {
      title: '',
      key: 'Like',
      render: (_, record) => (
        <Select
          placeholder="Like Type"
          allowClear
          options={likeTypes}
          value={record?.mine_like_type}
          disabled={record?.user_id === botUserId}
          onChange={(value) => handleInputChange(value, record)}
          className="search-input"
          showSearch
          optionFilterProp="label"
          style={{ marginLeft: 10 }}
        />
      ),
    },
    {
      title: 'Reply',
      key: 'Reply',
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => showModal(record, 'reply')}
          disabled={record?.user_id === botUserId}
        >
          <ReloadOutlined />
        </Button>
      ),
    },
    {
      title: 'Edit',
      key: 'Edit',
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => showModal(record, 'edit')}
          disabled={record?.user_id !== botUserId}
        >
          <EditOutlined />
        </Button>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (_, record) => (
        <Button
          type="primary"
          danger
          onClick={() => showDeleteConfirm(record)}
          disabled={record?.user_id !== botUserId}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ]

  return (
    <>
      <Spin spinning={loading}>
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={transformedData}
            columns={columns}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50', '100'],
              total: totalRecords,
            }}
            onChange={handleTableChange}
          />
        </DndProvider>
      </Spin>
      <Modal
        title={modalMode === 'edit' ? 'Edit Comment' : 'Reply to Comment'}
        visible={modalVisible}
        onOk={handleSave}
        onCancel={() => setModalVisible(false)}
        okText={modalMode === 'edit' ? 'Save' : 'Reply'}
      >
        <div style={{ position: 'relative' }}>
          <Input.TextArea
            id="modalTextArea"
            rows={4}
            value={modalText}
            onChange={(e) => setModalText(e.target.value)}
            placeholder={
              modalMode === 'edit' ? 'Edit text here...' : 'Write a reply...'
            }
          />
          <Button
            type="text"
            icon={<SmileOutlined />}
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 1,
            }}
          />
          {showEmojiPicker && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: '100%',
                marginTop: '5px',
                zIndex: 10,
                background: 'white',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default ViewComment
