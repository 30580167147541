import { useState } from 'react'
import { Input, Divider } from 'antd'
import SectionTreasureHunter from './sectiontreasurehunter'
const { Search } = Input

const TreasureHunter = () => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  return (
    <>
      <div className="container">
        <Search
          placeholder="Search by name"
          onSearch={handleSearch}
          style={{ width: '16.5%' }}
          allowClear
        />
      </div>
      <Divider style={{ margin: '10px 0' }} />
      <div className="sections-container">
        <SectionTreasureHunter searchTerm={searchTerm} />
      </div>
    </>
  )
}

export default TreasureHunter
