import { useState } from 'react'
import { Button, Divider, Input, Typography, Select, message } from 'antd'
import { saveEditMetaverse } from 'src/api/metaverse'
import { ACCESS_LEVELS } from 'src/constants'
import { useNavigate, useParams } from 'react-router-dom'
const { Title } = Typography
import './EditMetaverse.css'

const EditMetaverse = () => {
  const params = new URLSearchParams(location.search)
  const { id } = useParams()

  const [name, setName] = useState(params.get('title'))

  const [editedTitle, setEditedTitle] = useState(params.get('title'))
  const [editedDesc, setEditedDesc] = useState(params.get('desc'))
  const [editedTitleFr, setEditedTitleFr] = useState(params.get('titleFr'))
  const [editedImg, setEditedImg] = useState(params.get('img'))
  const [editedThumbImg, setEditedThumbImg] = useState(params.get('thumbImg'))
  const [newImage, setNewImage] = useState(null)
  const [newThumbImage, setNewThumbImage] = useState(null)
  const navigate = useNavigate()
  const [accessLevels, setAccessLevels] = useState(
    params.getAll('accessLevels')
  )

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setNewImage(file)
      setEditedImg(imageUrl)
    }
  }

  const handleSave = () => {
    if (
      editedTitle !== '' &&
      editedTitleFr !== '' &&
      editedDesc !== '' &&
      newImage !== '' &&
      newThumbImage !== ''
    ) {
      saveEditMetaverse(
        id,
        name,
        editedTitle,
        editedTitleFr,
        editedDesc,
        accessLevels,
        newImage,
        newThumbImage
      ).then(() => {
        navigate('/metaverse')
      })
    } else {
      message.error('All fields are required!')
    }
  }
  const onCancel = () => {
    navigate('/metaverse')
  }

  const handleThumbImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setNewThumbImage(file)
      setEditedThumbImg(imageUrl)
    }
  }

  const handleAccessLevelChange = (value) => {
    setAccessLevels(value)
  }

  return (
    <div className="edit-metaverse-container">
      <Title level={3}>Edit Metaverse</Title>
      <Divider />
      <div>
        <div className="inline-form-item">
          <label>Title:</label>
          <Input
            placeholder="Title"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
        </div>
        <div className="inline-form-item">
          <label>Title French:</label>
          <Input
            placeholder="Title French"
            value={editedTitleFr}
            onChange={(e) => setEditedTitleFr(e.target.value)}
          />
        </div>
        <div className="inline-form-item">
          <label>Description:</label>
          <Input.TextArea
            placeholder="Description"
            value={editedDesc}
            onChange={(e) => setEditedDesc(e.target.value)}
          />
        </div>

        <div className="inline-form-item">
          <label>Access Level:</label>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select access level"
            defaultValue={accessLevels}
            onChange={handleAccessLevelChange}
            options={ACCESS_LEVELS}
          />
        </div>
        <div className="button-container">
          <Button
            onClick={() => document.getElementById('upload-image')?.click()}
            className="edit-img-button"
          >
            Edit Image
          </Button>
          <input
            id="upload-image"
            type="file"
            hidden
            accept="image/*"
            onChange={handleImageUpload}
          />
          <Button
            onClick={() => document.getElementById('upload-thumbnail')?.click()}
            className="edit-thumb-button"
          >
            Edit Thumbnail
          </Button>
          <input
            id="upload-thumbnail"
            type="file"
            hidden
            accept="image/*"
            onChange={handleThumbImageUpload}
          />
        </div>
        <div className="image-preview">
          <img src={editedImg} alt="Edited Image" />
          <img
            src={editedThumbImg}
            alt="Edited Thumbnail"
            className="thumb-img"
          />
        </div>
        <div className="button-container" style={{ marginTop: 16 }}>
          <Button className="save-button" onClick={handleSave}>
            Save
          </Button>
          <Button className="edit-thumb-button" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EditMetaverse
