import { message } from 'antd'
import { fetchUtil as fetch } from 'src/utils/fetch'
import { saveAuditData } from 'src/api/audit'

export const savePost = async (country, userId, text, images) => {
  const input = new FormData()
  input.append('text', text)
  input.append('country', country)
  input.append('userId', userId)
  if (images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      input.append('attachments', images[i])
    }
  }
  const response = await fetch('/social/posts/bot', {
    method: 'POST',
    body: input,
    headers: {
      role: 'ADMIN',
    },
  })
  saveAuditData(
    'CREATE',
    'BOT-POST',
    JSON.stringify({
      country: country,
      text: text,
      attachment: JSON.stringify(images),
    })
  )
  return 'success'
}

export const saveMessageUser = async (userId, botUserId, text, images) => {
  const input = new FormData()
  input.append('text', text)
  input.append('userId', userId)
  input.append('botUserId', botUserId)

  if (images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      input.append('attachment', images[i])
    }
  }
  await fetch('/chatroom/bot/message/user', {
    method: 'POST',
    body: input,
    headers: {
      role: 'ADMIN',
    },
  })
  saveAuditData(
    'CREATE',
    'BOT-MESSAGE-USER',
    JSON.stringify({
      userId: userId,
      text: text,
      attachment: JSON.stringify(images),
    })
  )
  return 'success'
}

export const saveMessage = async (country, userId, text, images) => {
  const input = new FormData()
  input.append('text', text)
  input.append('userId', userId)
  input.append('country', country)

  if (images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      input.append('attachment', images[i])
    }
  }
  await fetch('/chatroom/bot/message', {
    method: 'POST',
    body: input,
    headers: {
      role: 'ADMIN',
    },
  })
  saveAuditData(
    'CREATE',
    'BOT-MESSAGE',
    JSON.stringify({
      country: country,
      text: text,
      attachment: JSON.stringify(images),
    })
  )
  return 'success'
}

export const getUserPosts = async (
  page,
  size,
  country,
  userId = '',
  badContent
) => {
  try {
    const requestBody: {
      country: string
      userId?: string
      badContent?: boolean
    } = {
      country,
    }
    if (userId !== '') {
      requestBody.userId = userId
    }
    if (badContent === true) {
      requestBody.badContent = badContent
    }
    const { data } = await fetch(
      `/social/posts/all?page=${page}&size=${size}`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          role: 'ADMIN',
        },
      }
    )
    return data
  } catch (e) {
    message.error('Get Bad Content Error !', e)
  }
}

export const getBotPost = async (page, size, country, userId = '') => {
  try {
    const requestBody: {
      country: string
      userId?: string
    } = {
      country,
    }
    if (userId !== '') {
      requestBody.userId = userId
    }
    const { data } = await fetch(
      `/social/posts/botPosts?page=${page}&size=${size}`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          role: 'ADMIN',
        },
      }
    )
    return data
  } catch (e) {
    message.error('Get Bad Content Error !', e)
  }
}

export const getBotPostComment = async (page, size, postId, botUserId = '') => {
  try {
    const { data } = await fetch(
      `/social/posts/comments/${postId}?page=${page}&size=${size}&botUserId=${botUserId}`,
      {
        headers: {
          role: 'ADMIN',
        },
      }
    )
    return data
  } catch (e) {
    message.error('Get Bad Content Error !', e)
  }
}

export const getSocialAbuse = async (
  page,
  size,
  userId = '',
  abuserId = ''
) => {
  try {
    const requestBody: {
      userId?: string
      abuserId?: string
    } = {}
    if (userId !== '') {
      requestBody.userId = userId
    }
    if (abuserId !== '') {
      requestBody.abuserId = abuserId
    }
    const { data } = await fetch(`/misc/abuse?page=${page}&size=${size}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Get Bad Content Error !', e)
  }
}

export const getUserComment = async (page, size, userId = '', badContent) => {
  try {
    const requestBody: {
      userId?: string
      badContent?: boolean
    } = {}
    if (userId !== '') {
      requestBody.userId = userId
    }
    if (badContent === true) {
      requestBody.badContent = badContent
    }
    const { data } = await fetch(
      `/social/posts/comments?page=${page}&size=${size}`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          role: 'ADMIN',
        },
      }
    )
    return data
  } catch (e) {
    message.error('Get Bad Content Error !', e)
  }
}
export const deleteCommentById = async (record) => {
  try {
    await fetch(`/social/posts/badComments/${record.id}`, {
      method: 'DELETE',
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData(
      'DELETE',
      'SOCIAL-COMMENT',
      JSON.stringify({
        text: record.text,
        attachment: JSON.stringify(record.attachments),
      })
    )
  } catch (e) {
    message.error('Delete Bad Content Error !', e)
  }
}

export const deleteContentById = async (record, recodType) => {
  try {
    saveAuditData(
      'DELETE',
      recodType,
      JSON.stringify({
        text: record.text,
        attachment: JSON.stringify(record.attachments),
      })
    )

    await fetch(`/social/posts/badContent/${record.id}`, {
      method: 'DELETE',
      headers: {
        role: 'ADMIN',
      },
    })
  } catch (e) {
    message.error('Delete Bad Content Error !', e)
  }
}

export const updateBotPost = async (updatedData) => {
  const input = new FormData()
  if (updatedData.text != '') {
    input.append('text', updatedData.text)
  }
  if (updatedData.attachmentsToDelete?.length > 0) {
    for (let i = 0; i < updatedData.attachmentsToDelete.length; i++) {
      input.append('attachmentsToDelete', updatedData.attachmentsToDelete[i])
    }
  }

  if (updatedData.newAttachments.length > 0) {
    for (let i = 0; i < updatedData.newAttachments.length; i++) {
      input.append('attachments', updatedData.newAttachments[i].originFileObj)
    }
  }
  await fetch(`/social/posts/edit/${updatedData.id}`, {
    method: 'POST',
    body: input,
    headers: {
      role: 'ADMIN',
    },
  })
  saveAuditData(
    'EDIT',
    'BOT-POST',
    JSON.stringify({
      text: updatedData.text,
      noOfAttachment: JSON.stringify(updatedData.newAttachments.length),
      deletedAttachement: JSON.stringify(updatedData.attachmentsToDelete),
    })
  )
  return 'success'
}

export const replyComment = async (updatedData) => {
  const input = { text: updatedData.text, bot_user_id: updatedData.bot_user_id }

  await fetch(
    `/social/posts/${updatedData.postId}/comment/reply/${updatedData.id}`,
    {
      method: 'POST',
      body: JSON.stringify(input),
      headers: {
        role: 'ADMIN',
      },
    }
  )
  message.success('Replied Successfully!')
  saveAuditData(
    'REPLY',
    'BOT-COMMENT',
    JSON.stringify({
      text: updatedData.text,
    })
  )
  return 'success'
}

export const editComment = async (updatedData) => {
  const input = { text: updatedData.text, bot_user_id: updatedData.bot_user_id }

  await fetch(
    `/social/posts/${updatedData.postId}/comment/edit/${updatedData.id}`,
    {
      method: 'PUT',
      body: JSON.stringify(input),
      headers: {
        role: 'ADMIN',
      },
    }
  )
  message.success('Edit Comment Successfull!')
  saveAuditData(
    'EDIT',
    'BOT-COMMENT',
    JSON.stringify({
      text: updatedData.text,
    })
  )
  return 'success'
}

export const addLike = async (updatedData) => {
  await fetch(
    `/social/posts/comment/${updatedData.id}/like/${updatedData.reaction}?botUserId=${updatedData.botUserId}`,
    {
      method: 'PUT',
      headers: {
        role: 'ADMIN',
      },
    }
  )
  message.success('Comment Reacted Successfull!')
  saveAuditData(
    'LIKE',
    'BOT-COMMENT',
    JSON.stringify({
      text: updatedData.text,
      likeType: updatedData.reaction,
    })
  )
  return 'success'
}

export const unLike = async (updatedData) => {
  await fetch(
    `/social/posts/${updatedData.id}/unlike?botUserId=${updatedData.botUserId}`,
    {
      method: 'DELETE',
      headers: {
        role: 'ADMIN',
      },
    }
  )
  message.success('Comment Reacted Successfull!')
  saveAuditData(
    'UNLIKE',
    'BOT-COMMENT',
    JSON.stringify({
      text: updatedData.text,
    })
  )
  return 'success'
}
