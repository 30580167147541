import { message } from 'antd'
import { omit, pick } from 'ramda'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
import * as apiPlaylist from './playlist'
import { saveAuditData } from 'src/api/audit'

export const getAll = async (params) => {
  const query = buildQuery(params)
  const { data } = await fetch(`/playlist/tracks?${query}`)
  return data
}

export const fetchPlaylistByQuery = async (query) => {
  try {
    const { data } = await apiPlaylist.getAll({
      name: query,
      page: 0,
      total: 0,
      size: 50,
    })
    return data
  } catch (e) {
    message.error('fetchPlaylistByQuery error !')
  }
}

export const addTrackToPlaylist = async ({ trackId, playlistId }) => {
  try {
    await fetch(`/playlist/${playlistId}/track/${trackId}`, {
      method: 'PATCH',
    })
  } catch (e) {
    message.error('addTrackToPlaylist error')
  }
}

export const removeTrackFromPlaylist = async ({ trackId, playlistId }) => {
  try {
    await fetch(`/playlist/${playlistId}/track/${trackId}`, {
      method: 'DELETE',
    })
  } catch (e) {
    message.error('removeTrackFromPlaylist error')
  }
}

export const addTagToTrack = async ({ trackId, tagId }) => {
  try {
    await fetch(`/playlist/track/${trackId}/tag/${tagId}`, {
      method: 'PATCH',
    })
  } catch (e) {
    message.error('addTagToTrack error')
  }
}

export const removeTagFromTrack = async ({ trackId, tagId }) => {
  try {
    await fetch(`/playlist/track/${trackId}/tag/${tagId}`, {
      method: 'DELETE',
    })
  } catch (e) {
    message.error('removeTagFromTrack error')
  }
}

export const search = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'size'], params))
  const body = omit(['page', 'size'], params)
  if (Object.keys(body).length === 0) {
    message.error('No searching query !')
    return
  }
  if (body.tags) {
    body.tags = body.tags.split(',')
  }
  if (body.playlists) {
    body.playlists = body.playlists.split(',')
  }
  if (body.artists) {
    body.artists = body.artists.split(',')
  }
  try {
    const { data } = await fetch(`/playlist/track/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    return data
  } catch (e) {
    message.error('search track error !')
  }
}

export const searchArtist = async (params: Record<string, any>) => {
  try {
    const query = buildQuery({
      page: 0,
      size: 50,
    })
    const { data } = await fetch(`/playlist/artist/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(params),
    })
    return data.data
  } catch (e) {
    message.error('searchArtist error !')
  }
}
export const getTrackCategories = async () => {
  try {
    const res = await fetch(`/playlist/track/category`)

    if (Array.isArray(res?.data)) {
      const trendingTracks = res.data.filter(
        (track) => track.type === 'TRENDING'
      )

      return trendingTracks
    }
    throw 'Get all categories response wrong format'
  } catch (e) {
    message.error('get track categories error ')
  }
}
export const updateTracksInsideCategory = async (
  category: string,
  categoryType: string,
  trackIds: string[]
) => {
  try {
    await fetch(`/playlist/track/category/${category}`, {
      method: 'POST',
      body: JSON.stringify({ trackIds }),
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData('EDIT', 'DISCOVERY-TRACK', categoryType)
  } catch (e) {
    message.error('update Tracks category error ')
  }
}
