import {
  Divider,
  Select,
  Table,
  Spin,
  Button,
  Input,
  message,
  Modal,
  Row,
  Col,
  Switch,
} from 'antd'
import { useEffect, useState, useCallback } from 'react'
import { getUserPosts, deleteContentById } from 'src/api/social'
import { DndProvider } from 'react-dnd'
import { manualFetch } from 'src/utils/fetch'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { fetchCountryProvider } from 'src/api/authen'
import { search } from 'src/api/user'
import './Userpost.css'
import { extractTextContent } from 'src/utils/stringUtil'

const { Search } = Input
const { confirm } = Modal

const UserPosts = () => {
  const [providerCountry, setProviderCountry] = useState('TN')
  const [countries, setCountries] = useState([])
  const [badContent, setBadContent] = useState(false)
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(undefined)

  const [transformedData, setTransformedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)

  const [previewImage, setPreviewImage] = useState(null)
  const [previewVisible, setPreviewVisible] = useState(false)

  const fetchImage = useCallback((postid, attachmentId) => {
    const url = `${process.env.REACT_APP_API_URL}/api/social/posts/${postid}/file/${attachmentId}/download`
    return manualFetch(url, {
      headers: {
        accept: 'image/*',
      },
    })
      .then((response) => response.blob())
      .then((imageBlob) => {
        const imageUrl = URL.createObjectURL(imageBlob)
        return imageUrl
      })
  }, [])

  const transformData = async (data) => {
    if (data == null) {
      return null
    }
    const transformedArray = await Promise.all(
      data.map(async (item) => {
        const resourceContents = await getImages(item.attachments, item.id)
        return {
          ...item,
          key: item.id,
          username: item.user.username,
          createdOn: convertToCustomFormat(item.created_on),
          resourceContents: resourceContents,
        }
      })
    )
    return transformedArray
  }

  const getImages = async (attachments, postId) => {
    const fileContents = []
    if (attachments == null) {
      return fileContents
    }
    for (const attachment of attachments) {
      fileContents.push(await fetchImage(postId, attachment.attachmentId))
    }
    return fileContents
  }
  const handleDelete = async (record) => {
    try {
      setLoading(true)
      await deleteContentById(record, 'SOCIAL-POST')
      message.success('Content deleted successfully!')
      fetchUserPostData(currentPage, pageSize, username, providerCountry)
    } catch (error) {
      message.error('Failed to delete content.')
    }
  }

  const handleTableChange = (pagination: any) => {
    const { current, pageSize } = pagination
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
    fetchUserPostData(current, pageSize, username, providerCountry)
  }

  const fetchUserPostData = async (page, size, userId, providerCountry) => {
    try {
      setLoading(true)
      const response = await getUserPosts(
        page - 1,
        size,
        providerCountry,
        userId,
        badContent
      )
      const transformedData = await transformData(response.data)
      setTransformedData(transformedData)
      setTotalRecords(response.total_items)
    } finally {
      setLoading(false)
    }
  }

  const handleProviderCountryChange = (value) => {
    setProviderCountry(value)
  }

  const convertToCustomFormat = (timestamp) => {
    const date = new Date(timestamp)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
  }

  const handleSearch = async (value) => {
    if (!value) {
      setUsername('')
      setCurrentPage(1)
      setPageSize(10)
      fetchUserPostData(1, 10, '', providerCountry)
      return
    }

    const users = await findUser(value)

    if (users.length > 0) {
      const matchedUser = users.find((u) => u.username === value)
      const userId = matchedUser ? matchedUser.user_id : users[0].user_id
      setUsername(userId)
      setCurrentPage(1)
      setPageSize(10)
      fetchUserPostData(1, 10, userId, providerCountry)
    } else {
      setUsername('')
      setCurrentPage(1)
      setPageSize(10)
      fetchUserPostData(1, 10, '', providerCountry)
    }
  }

  const findUser = useCallback(async (value) => {
    if (!value) return
    setLoading(true)
    const res = await search({ username: value, size: 50 })
    setLoading(false)
    return res.data
  }, [])

  const handleToggleBadContent = (checked) => {
    setBadContent(checked)
    setCurrentPage(1)
  }

  const fetchCountry = async () => {
    try {
      const data = await fetchCountryProvider()
      const countryOptions = data.data.map((item) => ({
        label: item.countryName,
        value: item.countryCode,
      }))
      setCountries(countryOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure you want to delete this content?',
      content: 'Once deleted, this action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk: () => handleDelete(record),
      onCancel: () => {
        message.info('Deletion cancelled.')
      },
    })
  }

  const handleImageClick = (imageUrl) => {
    setPreviewImage(imageUrl)
    setPreviewVisible(true)
  }

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      width: 300,
      render: (text) => extractTextContent(text),
    },
    {
      title: 'Attachments',
      dataIndex: 'resourceName',
      key: 'resourceName',
      width: 500,
      render: (_, record) => {
        if (record.resourceContents && record.resourceContents.length > 0) {
          return (
            <Row gutter={[16, 16]} justify="start">
              {record.resourceContents.map((content, index) => {
                return (
                  <Col key={index} span={6}>
                    <img
                      src={content}
                      alt="Attachment"
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        border: '1px solid #ddd',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                      }}
                      onClick={() => handleImageClick(content)}
                    />
                  </Col>
                )
              })}
            </Row>
          )
        }
        return null
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdOn',
      key: 'createdOn',
      width: 200,
    },
    {
      title: '',
      key: 'delete',
      render: (_, record) => (
        <Button type="primary" danger onClick={() => showDeleteConfirm(record)}>
          Delete
        </Button>
      ),
    },
  ]

  useEffect(() => {
    fetchCountry()
    fetchUserPostData(currentPage, pageSize, username || '', providerCountry)
  }, [pageSize, currentPage, username, providerCountry, badContent])

  return (
    <>
      <Spin spinning={loading}>
        <Select
          placeholder="Select Country"
          value={providerCountry}
          onChange={handleProviderCountryChange}
          options={countries}
          style={{ marginLeft: '15px', width: '15%' }}
        />
        <Search
          placeholder="Search by username"
          onSearch={handleSearch}
          style={{ width: '16.5%', marginLeft: '15px' }}
          allowClear
        />
        <Switch
          checked={badContent}
          onChange={handleToggleBadContent}
          style={{ marginLeft: '15px' }}
          checkedChildren="Bad Content"
          unCheckedChildren="All Posts"
        />
        <Divider />
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={transformedData}
            columns={columns}
            rowClassName={(record) =>
              record.text_bad_content || record.image_bad_content
                ? 'bad-content-row'
                : ''
            }
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50', '100'],
              total: totalRecords,
            }}
            onChange={handleTableChange}
          />
        </DndProvider>
      </Spin>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        width="40%"
        style={{
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
        bodyStyle={{
          padding: 0,
          textAlign: 'center',
        }}
      >
        <img
          alt="Preview"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '70vh',
            objectFit: 'contain',
            borderRadius: '8px',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  )
}

export default UserPosts
