import { useState, useEffect } from 'react'
import { Button, Divider, Input, Typography, Select, Form, message } from 'antd'
import { Switch } from 'antd'
import { ACCESS_LEVELS } from 'src/constants'
import { saveEditTreasureHunter } from 'src/api/treasurehunter'
import { useNavigate, useParams } from 'react-router-dom'
const { Title, Text } = Typography
import { useLocation } from 'react-router-dom'
import './EditTreasureHunter.css'

const EditTreasureHunter = () => {
  const { id } = useParams()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const [editedTitle, setEditedTitle] = useState(params.get('title'))
  const [editedDesc, setEditedDesc] = useState(params.get('desc'))
  const [editedInstruction, setEditedInstruction] = useState(
    params.get('instruction')
  )

  const [editedImg, setEditedImg] = useState(params.get('img'))
  const [editedThumbImg, setEditedThumbImg] = useState(
    params.get('thumbnailImage')
  )
  const [newImage, setNewImage] = useState(null)
  const [newThumbImage, setNewThumbImage] = useState(null)
  const [accessLevels, setAccessLevels] = useState(
    params.getAll('accessLevels')
  )
  const navigate = useNavigate()

  const [enabled, setEnabled] = useState(params.get('enabled') === 'true')
  const [form] = Form.useForm()

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setNewImage(file)
      setEditedImg(imageUrl)
    }
  }

  const handleSave = async () => {
    const values = await form.validateFields()
    saveEditTreasureHunter(
      id,
      values.editedTitle,
      values.editedDesc,
      values.editedInstruction,
      newImage,
      newThumbImage,
      accessLevels,
      enabled
    ).then((response) => {
      navigate('/treasure-hunter')
    })
  }
  const onCancel = () => {
    navigate('/treasure-hunter')
  }

  const onChangeSwitch = (checked: boolean) => {
    setEnabled(!enabled)
  }

  const handleThumbImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setNewThumbImage(file)
      setEditedThumbImg(imageUrl)
    }
  }

  const handleAccessLevelChange = (value) => {
    setAccessLevels(value)
  }

  return (
    <div className="edit-treasure-hunter-container">
      <Title level={3}>
        <Text>Edit Treasure Hunter</Text>
      </Title>
      <Divider />
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          label="Title"
          name="editedTitle"
          initialValue={params.get('title')}
          rules={[{ required: true, message: 'Please enter the title' }]}
        >
          <Input placeholder="Title" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="editedDesc"
          initialValue={params.get('desc')}
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea placeholder="Description" />
        </Form.Item>

        <Form.Item
          label="Instruction"
          name="editedInstruction"
          initialValue={params.get('instruction')}
          rules={[{ required: true, message: 'Please enter the instruction' }]}
        >
          <Input.TextArea placeholder="Instruction" />
        </Form.Item>
        <Form.Item
          label="Access Level"
          name="accesslevel"
          initialValue={accessLevels}
          rules={[{ required: true, message: 'Please select access level' }]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select access level"
            defaultValue={accessLevels}
            onChange={handleAccessLevelChange}
            options={ACCESS_LEVELS}
          />
        </Form.Item>

        <Form.Item
          label="Enabled"
          name="enabled"
          valuePropName="checked"
          initialValue={enabled}
          rules={[
            { required: true, message: 'Please select the enabled state' },
          ]}
        >
          <Switch onChange={onChangeSwitch} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0 }}>
          <Button
            onClick={() => document.getElementById('upload-image')?.click()}
            className="edit-img-button"
          >
            Edit Image
          </Button>
          <input
            id="upload-image"
            type="file"
            hidden
            accept="image/*"
            onChange={handleImageUpload}
          />
          <Button
            onClick={() => document.getElementById('upload-thumbnail')?.click()}
            className="edit-thumb-button"
          >
            Edit Thumbnail
          </Button>
          <input
            id="upload-thumbnail"
            type="file"
            hidden
            accept="image/*"
            onChange={handleThumbImageUpload}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 0 }}>
          <div className="image-preview">
            <img src={editedImg} alt="Edited Image" />
            <img
              src={editedThumbImg}
              alt="Edited Thumbnail"
              className="thumb-img"
            />
          </div>
        </Form.Item>
        <div className="button-container" style={{ marginTop: 16 }}>
          <Button className="save-button" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </Form>
    </div>
  )
}

export default EditTreasureHunter
