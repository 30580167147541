import { Button, message, Modal, Select, Typography } from 'antd'
import React from 'react'
import debounce from 'src/utils/debounce'
import { getAll, search, updateStationsInsideCategory } from 'src/api/station'

import s from './index.module.scss'
import useForceRender from 'src/utils/ForceRender'

const { Option } = Select
const { Title } = Typography

interface AddingModalProps {
  category: any
  visible: boolean
  onDone: () => void
  onCancel: () => void
}

const AddingModal: React.FC<AddingModalProps> = ({
  category,
  visible,
  onDone,
  onCancel,
}) => {
  const forceRender = useForceRender()
  const selectedStation = React.useRef<Station>(null)
  const selectLoading = React.useRef<boolean>(false)
  const addLoading = React.useRef<boolean>(false)
  const stations = React.useRef<Station[]>([])

  const handleSearch = async (value) => {
    if (value !== '') {
      selectLoading.current = true
      forceRender()
      const { data } = await search({ text: value, size: 10 })
      stations.current = data
      selectLoading.current = false
      forceRender()
    }
  }

  const resetModal = React.useCallback(() => {
    selectedStation.current = null
    selectLoading.current = false
    addLoading.current = false
    stations.current = []
    forceRender()
  }, [])

  const handleSelect = React.useCallback((option) => {
    selectedStation.current = {
      id: option.value,
      name: option.label,
    }
    forceRender()
  }, [])

  const handleAdd = React.useCallback(async () => {
    if (!selectedStation.current) {
      message.warn('Select a Station first !')
      return
    }
    addLoading.current = true
    forceRender()
    const stationIds = category?.stations.map((i) => i.id)
    await updateStationsInsideCategory(category?.id, category?.type, [
      ...stationIds,
      selectedStation.current.id,
    ])
    message.success(
      `${selectedStation.current?.name} is added to ${category.type}`
    )
    resetModal()
    onDone && onDone()
  }, [selectedStation.current, category])

  const handleCancel = React.useCallback(() => {
    resetModal()
    onCancel && onCancel()
  }, [])

  const filteredResults = stations.current?.filter((item) => {
    return !category?.stations.find((i) => i.id === item.id)
  })

  return (
    <>
      <Modal
        title={`Add Station to ${category?.type}`}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            key="add"
            onClick={handleAdd}
            loading={addLoading.current}
          >
            Add
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Title level={5}>{category?.stations?.length} Stations added</Title>
        <Select
          allowClear
          showSearch
          autoFocus
          filterOption={false}
          onSearch={debounce(handleSearch, 500)}
          onSelect={handleSelect}
          size="large"
          placeholder="Search station"
          labelInValue={true}
          value={
            selectedStation.current
              ? {
                  label: selectedStation.current.name,
                  value: selectedStation.current.id,
                }
              : null
          }
          className={s.searchSelect}
          onClear={resetModal}
          loading={selectLoading.current}
        >
          {filteredResults?.map((item) => (
            <Option key={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}

AddingModal.displayName = 'AddingModal'
export default AddingModal
