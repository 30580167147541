import { message } from 'antd'

import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
import { saveAuditData } from 'src/api/audit'

export const getMetaverseCategory = async () => {
  try {
    const { data } = await fetch('/metaverse-rooms/rooms/categories')
    return data
  } catch (e) {
    message.error('Get Metaverse Category error!')
  }
}

export const getMetaverseRooms = async () => {
  try {
    const { data } = await fetch(`/metaverse-rooms/rooms/room-categories`)
    return data
  } catch (e) {
    message.error('Get Metaverse Rooms error!')
  }
}

export const getFeaturedMetaverseRooms = async () => {
  try {
    const { data } = await fetch(`/metaverse-rooms/rooms/featuredVenue`)
    return data
  } catch (e) {
    message.error('Get Featured Metaverse Rooms error!')
  }
}

export const setFeaturedMetaverseRooms = async (roomId) => {
  try {
    const { data } = await fetch(
      `/metaverse-rooms/rooms/featuredVenue/${roomId}`,
      {
        method: 'PATCH',
        headers: {
          role: 'ADMIN',
        },
      }
    )
    saveAuditData('EDIT', 'FEATUREDROOM', roomId)
    message.success('Success featured room edit!')
    return data
  } catch (e) {
    message.error('Fail to save featured metaverse rooms!')
  }
}

export const getMetaverseRoomsByCategory = async (category) => {
  try {
    const { data } = await fetch(`/metaverse-rooms/rooms/${category}`)
    return data
  } catch (e) {
    message.error('Get Metaverse Rooms by Category error!')
  }
}

export const saveEditMetaverse = async (
  id,
  name,
  title,
  titleFr,
  desc,
  accessLevels,
  img,
  thumbImage
) => {
  try {
    const input = new FormData()
    input.append(
      'roomEditDto',
      new Blob(
        [
          JSON.stringify({
            id: id,
            title: title,
            titleFr: titleFr,
            description: desc,
            accessLevels: accessLevels,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    input.append('image', img)
    input.append('thumbImage', thumbImage)

    const { data } = await fetch('/metaverse-rooms/rooms/edit', {
      method: 'POST',
      body: input,
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData('EDIT', 'METAVERSE', name)
    message.success('Success Metaverse Room Edit!')
    return 'success'
  } catch (e) {
    message.error('Edit Metaverse Room error!')
    return 'failed'
  }
}
export const getAllRoomCategories = async () => {
  try {
    const res = await fetch(`/metaverse-rooms/rooms/room-categories`)
    if (Array.isArray(res?.data)) {
      const categories = {
        MUSIC: [],
        COUNTRIES: [],
        TWINS: [],
      }
      const twinsRooms = []

      res.data.forEach((room) => {
        if (room.type === 'MUSIC' || room.type === 'COUNTRIES') {
          categories[room.type].push(room)
        } else if (
          room.type === 'CLUB' ||
          room.type === 'CASINO' ||
          room.type === 'STADIUM'
        ) {
          room.rooms.forEach((rooms) => {
            twinsRooms.push({ ...rooms })
          })
        }
      })
      twinsRooms.sort(
        (a, b) => Number(a.twinSortIndex) - Number(b.twinSortIndex)
      )
      categories.TWINS.push({ type: 'TWINS', id: 'TWINS', rooms: twinsRooms })

      const result = [
        ...categories.MUSIC,
        ...categories.COUNTRIES,
        ...categories.TWINS,
      ]

      return result
    }
    throw 'Get all categories response wrong format'
  } catch (e) {
    message.error('getAll categories error ')
  }
}
export const updateMetaverseOrder = async (
  categoryId: string,
  updatedCategory: MetaverseRoomCategory
) => {
  try {
    await fetch(`/metaverse-rooms/rooms/update-order/${categoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCategory),
    })
    saveAuditData('EDIT', 'METAVERSEORDER', categoryId)
  } catch (e) {
    message.error('updateMetaverses category error')
  }
}
export const getDiscovery = async () => {
  try {
    const { data } = await fetch('/metaverse-rooms/rooms/discovery')
    return data
  } catch (e) {
    message.error('Get Metaverse Category error!')
  }
}
export const fetchTriviaQuestionsAPI = async (type: string, page: number, size: number) => {

  try {

    const { data } = await fetch(`/metaverse-rooms/games/trivia/${type}?page=${page}&size=${size}`)

    return data
  } catch (error) {

    message.error('Error fetching trivia questions')

    throw error
  }
}
export const validateQuestion = (question: Question): string | null => {
  if (!question.text.trim()) {
    return "Question text is required!";
  }
  if (question.category == null || question.category.trim() === "") {
    return "Question category is required!";
  }
  if (question.type == null || question.type.trim() === "") {
    return "Question type is required!";
  }
  if (question.type !== "TRUE_FALSE" && (!question.answers || question.answers.length === 0)) {
    return "Please add at least one answer choice!";
  }

  if (question.type === "SINGLE_CHOICE" && (!question.correctAnswerIds || question.correctAnswerIds.length !== 1)) {
    return "Single choice question must have exactly one correct answer.";
  }

  if (question.type === "MULTIPLE_CHOICE" && (!question.correctAnswerIds || question.correctAnswerIds.length === 0)) {
    return "Multiple choice question must have at least one correct answer.";
  }

  if (question.type === "TRUE_FALSE" && (question.correctAnswer === null || question.correctAnswer === undefined)) {
    return "True/False question must have a correct answer.";
  }
  const hasEmptyAnswer = question.answers.some(answer => answer.text.trim() === "");
  if(hasEmptyAnswer){
    return "Empty answers are not allowed.";
  }
  return null; 
};

export const getAllTriviaCategories = async () => {
  try {
    const { data } = await fetch(`/metaverse-rooms/games/trivia/quiz-category`)

    return data
  } catch (e) {
    message.error('get Trivia categories error ')
  }
}
export const getAllTriviaQuizType = async () => {
  try {
    const { data } = await fetch(`/metaverse-rooms/games/trivia/quiz-type`)

    return data
  } catch (e) {
    message.error('get Trivia quiz type error ')
  }
}

export const createTriviaQuestion = async (newQuestion: Question) => {
  try {
  const { data } = await fetch("/metaverse-rooms/games/trivia", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newQuestion),
  })
  return data
} catch (e) {
  message.error('Search Games Error !')
}
}
export const updateTriviaQuestion = async (id:string,newQuestion: Question) => {
  try {
  const { data } = await fetch(`/metaverse-rooms/games/trivia/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newQuestion),
  })
  return data
} catch (e) {
  message.error('Search Games Error !')
}
}
export const deleteTriviaQuestionAPI = async (questionId: string) => {
  
  try {
    const { data } = await fetch(`/metaverse-rooms/games/trivia/${questionId}`, {
        method: "DELETE",
    })
    message.success('Delete question Successful')
    return data
  } catch (e) {
    message.error('Delete question Error !')
  }
};
export const searchTriviaQuestion = async (searchDto: SearchQuestion,page: number, size: number) => {
  try {
    if (searchDto.category === "" || searchDto.category === undefined) {
      const { category, ...rest } = searchDto; 
      searchDto = rest;
    }
  const { data } = await fetch(`/metaverse-rooms/games/trivia/search?page=${page}&size=${size}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(searchDto),
  })
  return data
} catch (e) {
  message.error('Search Trivia Error !')
}
}
