import { Divider, Select, Table, Spin, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { users, getAuditData } from 'src/api/audit'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

interface UsersMap {
  [userId: number]: string
}

const Audit = () => {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(undefined)
  const [auditUserData, setAuditUserData] = useState({
    data: [],
    current_page: 0,
    number_of_elements: 0,
    total_pages: 0,
    total_items: 0,
  })
  const [transformedData, setTransformedData] = useState([])
  const [userData, setUserData] = useState([])
  const [usersMap, setUsersMap] = useState<UsersMap>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [usersLoaded, setUsersLoaded] = useState(false)

  const addUser = (userId, username) => {
    setUsersMap((prevUsersMap) => ({
      ...prevUsersMap,
      [userId]: username,
    }))
  }

  const transformData = (data) => {
    const transformedArray = data.map((item) => ({
      ...item,
      username: usersMap[item.userId] || 'Unknown User',
      createdOn: convertToCustomFormat(item.createdOn),
    }))

    return transformedArray
  }

  const handleTableChange = (pagination: any) => {
    setTransformedData([])
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const fetchUserData = async () => {
    try {
      const response = await users()

      const usersList = Object.entries(response).map(([user_id, username]) => ({
        label: username,
        value: user_id,
      }))

      usersList.forEach((user) => addUser(user.value, user.label))
      setUserData(usersList)
      setUsersLoaded(true)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const fetchAuditData = async (page, size, userId) => {
    setLoading(true)
    try {
      const response = await getAuditData(page - 1, size, userId)
      setTransformedData(transformData(response.data))
      setTotalRecords(response.total_items)
    } finally {
      setLoading(false)
    }
  }
  const convertToCustomFormat = (isoDate) => {
    const date = new Date(isoDate)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  useEffect(() => {
    if (usersLoaded) {
      fetchAuditData(currentPage, pageSize, username)
    }
  }, [pageSize, currentPage, username, usersLoaded])

  const handleInputChange = (e) => {
    setUsername(e)
    setCurrentPage(1)
    setPageSize(10)
  }

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      width: 200,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
    },
    {
      title: 'Resource Id',
      dataIndex: 'resourceId',
      key: 'resourceId',
      width: 550,
      ellipsis: true,
      render: (text) =>
        text?.length > 15 ? (
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        ) : (
          text
        ),
    },
    {
      title: 'Resource Name',
      dataIndex: 'resourceName',
      key: 'resourceName',
      width: 200,
    },
    {
      title: 'Date',
      dataIndex: 'createdOn',
      key: 'createdOn',
    },
  ]

  return (
    <>
      <Spin spinning={loading}>
        <Select
          placeholder="Username"
          allowClear
          options={userData}
          onChange={handleInputChange}
          value={username}
          className="search-input"
          showSearch
          optionFilterProp="label"
        />

        <Divider />
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={transformedData}
            columns={columns}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50', '100'],
              total: totalRecords,
            }}
            onChange={handleTableChange}
          />
        </DndProvider>
      </Spin>
    </>
  )
}

export default Audit
