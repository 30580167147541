import { message } from 'antd'
import { fetchUtil as fetch } from 'src/utils/fetch'
import { saveAuditData } from 'src/api/audit'

export const getMazes = async (page, size, name = '') => {
  try {
    const { data } = await fetch('/metaverse-rooms/maze/searchAdmin', {
      method: 'POST',
      body: JSON.stringify({
        size: size,
        page: page,
        name: name,
      }),
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Search Maze Error !')
  }
}

export const saveEditMaze = async (
  id,
  title,
  titleFr,
  desc,
  descFr,
  instruction,
  instructionFr,
  img,
  thumbImage,
  enabled,
  level,
  accessLevels
) => {
  try {
    const input = new FormData()
    input.append(
      'mazeEditDto',
      new Blob(
        [
          JSON.stringify({
            id: id,
            title: title,
            titleFr: titleFr,
            description: desc,
            descriptionFr: descFr,
            enabled: enabled,
            instruction: instruction,
            instructionFr: instructionFr,
            gameLevel: level,
            accessLevels: accessLevels,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    input.append('image', img)
    input.append('thumbImage', thumbImage)

    await fetch('/metaverse-rooms/maze/edit', {
      method: 'POST',
      body: input,
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData('EDIT', 'MAZE', id)
    message.success('Success Maze Edit!')
    return 'success'
  } catch (e) {
    message.error('Edit Maze error!')
    return 'failed'
  }
}
export const getMazeCategories = async () => {
  try {
    const res = await fetch(`/metaverse-rooms/maze/maze-discovery`)
    
    if (Array.isArray(res?.data)) {
      return res.data
    }
    throw 'Get all categories response wrong format'
  } catch (e) {
    message.error('getAll categories error ')
  }
}
