import React, { useState } from "react";
import { Card, Checkbox, Space, Button } from "antd";
import EditQuizQuestion from "./EditQuizQuestion";

interface Props {
  questions: Question[];
  dispatch: React.Dispatch<any>;
  onUpdate: (newQuestion: Question) => void;
  onDelete: (questionId: string) => void;
}

const MultiAnswerQuiz: React.FC<Props> = ({ questions, dispatch,onUpdate,onDelete }) => {
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
   const [isModalVisible, setIsModalVisible] = useState(false); 

  const handleSave = (updatedQuestion: Question) => {
     
    onUpdate(updatedQuestion);

  };
  const openModal = (question: Question) => {
    setSelectedQuestion(question);
    setIsModalVisible(true); 
  };
  const handleDeleteQuestion = (questionId: string) => {
   
    onDelete(questionId);

  };
  return (
    <div className="question-list">
      {
        questions.map((question) => (
          <Card
            key={question.id}
            style={{ marginBottom: 20, cursor: "pointer" }}
            onClick={() => openModal(question)}
          >
            <div className="question-card-content">
              <h3>{question.text}</h3>
              <div className="answers">
                <Checkbox.Group 
                  value={Array.isArray(question.correctAnswerIds) ? question.correctAnswerIds : []} 
                  onChange={(checkedValues) => dispatch({ 
                    type: "UPDATE_QUESTION", 
                    payload: { ...question, answer: checkedValues as string[] } 
                  })}
                >
                  <Space direction="horizontal">
                    {question.answers.map((ans) => (
                      <Checkbox key={ans.id} value={ans.id}>
                        {ans.text}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
                
                <Button
                              danger
                              onClick={e => {
                                e.stopPropagation(); 
                                handleDeleteQuestion(question.id);
                              }}
                              style={{ marginTop: 10 }}
                            >
                              Remove
                            </Button>
                  
              </div>
            </div>
          </Card>
        ))
      }
      <EditQuizQuestion
                                question={selectedQuestion}
                                onSave={handleSave}
                                visible={isModalVisible}
                                onCancel={() => setIsModalVisible(false)}                
        />
                
    </div>
  );
};

export default MultiAnswerQuiz;
