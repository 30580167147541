import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AppContext from 'src/hooks/AppContext'

const ProtectedRoute = ({ withAuth, requiredprivileges = [] }) => {
  const { isAuth, userInfo } = useContext(AppContext)

  const hasrequiredprivileges = requiredprivileges.some((privilege) =>
    userInfo?.permissions?.includes(privilege)
  )

  if (!withAuth) {
    return <Outlet />
  }

  if (!isAuth) {
    return <Navigate to="/login" />
  }

  if (requiredprivileges.length > 0 && !hasrequiredprivileges) {
    return <Navigate to="/unauthorized" />
  }

  return <Outlet />
}

export default ProtectedRoute
