import { useState, useEffect } from 'react'
import { Button, Divider, Input, Typography, Select, Form, Switch } from 'antd'
import { ACCESS_LEVELS } from 'src/constants'
import { saveEditGames } from 'src/api/game'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
const { Title, Text } = Typography
import './EditGames.css'
import { getGamesCategory } from 'src/api/game'

const EditGames = () => {
  const { id } = useParams()
  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const [editedImg, setEditedImg] = useState(params.get('img'))
  const [editedThumbImg, setEditedThumbImg] = useState(params.get('thumbImg'))
  const [newImage, setNewImage] = useState(null)
  const [newThumbImage, setNewThumbImage] = useState(null)
  const [categories, setCategories] = useState(params.getAll('categories'))
  const [accessLevels, setAccessLevels] = useState(
    params.getAll('accessLevels')
  )

  const navigate = useNavigate()
  const [category, setCategory] = useState([])

  const [enabled, setEnabled] = useState(params.get('enabled') === 'true')
  const [form] = Form.useForm()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getGamesCategory()
        const category = []
        for (let i = 0; i < data.length; i++) {
          category.push({
            label: data[i],
            value: data[i].toUpperCase(),
          })
        }
        setCategory(category)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setNewImage(file)
      setEditedImg(imageUrl)
    }
  }

  const handleSave = async () => {
    const values = await form.validateFields()
    saveEditGames(
      id,
      values.editedTitle,
      values.editedTitleFr,
      values.editedDesc,
      values.editedDescFr,
      values.editedInstruction,
      values.editedInstructionFr,
      newImage,
      newThumbImage,
      categories,
      accessLevels,
      enabled
    ).then(() => {
      navigate('/games')
    })
  }
  const defaultValuesOption = categories

  const onCancel = () => {
    navigate('/games')
  }

  const onChangeSwitch = () => {
    setEnabled(!enabled)
  }

  const handleThumbImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setNewThumbImage(file)
      setEditedThumbImg(imageUrl)
    }
  }

  const handleChange = (value) => {
    setCategories(value)
  }

  const handleAccessLevelChange = (value) => {
    setAccessLevels(value)
  }

  return (
    <div className="edit-games-container">
      <Title level={3}>
        <Text>Edit games</Text>
      </Title>
      <Divider />
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          label="Title"
          name="editedTitle"
          initialValue={params.get('title')}
          rules={[{ required: true, message: 'Please enter the title' }]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="Title French"
          name="editedTitleFr"
          initialValue={params.get('titleFr')}
          rules={[{ required: true, message: 'Please enter the French title' }]}
        >
          <Input placeholder="Title French" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="editedDesc"
          initialValue={params.get('desc')}
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item
          label="Description French"
          name="editedDescFr"
          initialValue={params.get('descFr')}
          rules={[
            { required: true, message: 'Please enter the French description' },
          ]}
        >
          <Input.TextArea placeholder="Description French" />
        </Form.Item>
        <Form.Item
          label="Instruction"
          name="editedInstruction"
          initialValue={params.get('instruction')}
          rules={[{ required: true, message: 'Please enter the instruction' }]}
        >
          <Input.TextArea placeholder="Instruction" />
        </Form.Item>
        <Form.Item
          label="Instruction French"
          name="editedInstructionFr"
          initialValue={params.get('instructionFr')}
          rules={[
            { required: true, message: 'Please enter the French instruction' },
          ]}
        >
          <Input.TextArea placeholder="Instruction French" />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
          initialValue={defaultValuesOption}
          rules={[{ required: true, message: 'Please select a category' }]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select Category"
            defaultValue={defaultValuesOption}
            onChange={handleChange}
            options={category}
          />
        </Form.Item>
        <Form.Item
          label="Access Level"
          name="accesslevel"
          initialValue={accessLevels}
          rules={[{ required: true, message: 'Please select access level' }]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select access level"
            defaultValue={accessLevels}
            onChange={handleAccessLevelChange}
            options={ACCESS_LEVELS}
          />
        </Form.Item>
        <Form.Item
          label="Enabled"
          name="enabled"
          valuePropName="checked"
          initialValue={enabled}
          rules={[
            { required: true, message: 'Please select the enabled state' },
          ]}
        >
          <Switch onChange={onChangeSwitch} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0 }}>
          <Button
            onClick={() => document.getElementById('upload-image')?.click()}
            className="edit-img-button"
          >
            Edit Image
          </Button>
          <input
            id="upload-image"
            type="file"
            hidden
            accept="image/*"
            onChange={handleImageUpload}
          />
          <Button
            onClick={() => document.getElementById('upload-thumbnail')?.click()}
            className="edit-thumb-button"
          >
            Edit Thumbnail
          </Button>
          <input
            id="upload-thumbnail"
            type="file"
            hidden
            accept="image/*"
            onChange={handleThumbImageUpload}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 0 }}>
          <div className="image-preview">
            <img src={editedImg} alt="Edited Image" />
            <img
              src={editedThumbImg}
              alt="Edited Thumbnail"
              className="thumb-img"
            />
          </div>
        </Form.Item>
        <div className="button-container" style={{ marginTop: 16 }}>
          <Button className="save-button" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </Form>
    </div>
  )
}

export default EditGames
