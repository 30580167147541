import React, { useState } from "react";
import { Card, Radio, Space, Button } from "antd";
import EditQuizQuestion from "./EditQuizQuestion";

interface Props {
  questions: Question[];
  dispatch: React.Dispatch<any>;
  onUpdate: (newQuestion: Question) => void;
  onDelete: (questionId: string) => void;
}

const TrueFalseQuiz: React.FC<Props> = ({ questions, dispatch,onUpdate,onDelete }) => {
  
const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false); 

  const handleSave = (updatedQuestion: Question) => {
 
    onUpdate(updatedQuestion);
  };

  const openModal = (question: Question) => {
    setSelectedQuestion(question);
    setIsModalVisible(true); 
  };
  const handleDeleteQuestion = (questionId: string) => {
   
    onDelete(questionId);

  };
  return (
    <div className="question-list">
      {questions.length === 0 ? (
        <p>No questions available.</p>
      ) : (
        questions.map((question) => (
          <Card
            key={question.id}
            style={{ marginBottom: 20, cursor: "pointer" }}
            onClick={() => openModal(question)}
          >
            <div className="question-card-content">
              <h3>{question.text}</h3>
              <div className="answers">
                <Radio.Group value={question.correctAnswer?.toString()}>
                  <Space direction="horizontal">
                    <Radio value="true">True</Radio>
                    <Radio value="false">False</Radio>
                  </Space>
                </Radio.Group>
                <Button
                              danger
                              onClick={e => {
                                e.stopPropagation(); 
                                handleDeleteQuestion(question.id);
                              }}
                              style={{ marginTop: 10 }}
                            >
                              Remove
                            </Button>
              </div>
            </div>
          </Card>
        ))
      )}
      <EditQuizQuestion
        question={selectedQuestion}
        visible={isModalVisible}
        onSave={handleSave}
        onCancel={() => setIsModalVisible(false)} 
      />
    </div>
  );
};

export default TrueFalseQuiz;
