import React, { useEffect, useCallback } from 'react'
import { Divider, Typography, Spin, message } from 'antd'
import { DndProvider } from 'react-dnd'
import CarouselItem from './carousel-item'

import { HTML5Backend } from 'react-dnd-html5-backend'
import useForceRender from 'src/utils/ForceRender'
import { clone } from 'ramda'
import * as api from 'src/api/station'
import AddingModal from './addStations-modal'
import s from './index.module.scss'
import Carousel from './carousel'

const { Title } = Typography

const StationDiscovery: React.FC = () => {
  const forceRender = useForceRender()
  const selectedCategory = React.useRef<StationCategory>(null)
  const showAdding = React.useRef<boolean>(false)
  const loading = React.useRef<boolean>(true)
  const data = React.useRef<any[]>([])

  const loadPageData = useCallback(() => {
    const categoryName = 'TRENDING'

    api.getStationCategories(categoryName).then((categories) => {
      loading.current = false
      data.current = categories
      forceRender()
    })
  }, [])

  useEffect(() => {
    loadPageData()
  }, [])

  const onModalCancel = React.useCallback(() => {
    selectedCategory.current = null
    showAdding.current = false
    forceRender()
  }, [])

  const onAddClick = React.useCallback((category: StationCategory) => {
    selectedCategory.current = category
    showAdding.current = true
    forceRender()
  }, [])

  const onModalDone = React.useCallback(() => {
    loading.current = true
    onModalCancel()
    loadPageData()
  }, [])

  const updateOrdering = React.useCallback(
    async (dragId: string, dropId: string, category: StationCategory) => {
      const categoryNew = data.current.find(
        (cat: StationCategory) => cat.id === category.id
      ) as StationCategory

      const dragIndex = categoryNew.stations.findIndex((i) => i.id === dragId)

      const dropIdIndex = categoryNew.stations.findIndex((i) => i.id === dropId)

      let dropIndex: number

      if (dropIdIndex === 0) {
        dropIndex = dropIdIndex
      } else if (dragIndex > dropIdIndex) {
        dropIndex = dropIdIndex
      } else {
        dropIndex = dropIdIndex + 1
      }
      const dragItem = categoryNew.stations[dragIndex]
      const tempItem = { id: 'temp' } as any
      const cloneItems = clone(categoryNew.stations)
      cloneItems[dragIndex] = tempItem
      cloneItems.splice(dropIndex, 0, dragItem)

      const orderedItems = cloneItems.filter((i) => i.id !== 'temp')

      const StationIds = orderedItems.map((i) => i.id)
      loading.current = true
      forceRender()
      await api.updateStationsInsideCategory(
        categoryNew.id,
        category.type,
        StationIds
      )
      message.success(`Updated Stations ordering of ${categoryNew.name}`)
      loadPageData()
    },
    [data]
  )

  const handleRemoving = React.useCallback(
    async (station: Station, category: StationCategory) => {
      loading.current = true
      forceRender()
      const stationIds = category.stations?.reduce((IDs, current) => {
        if (current.id !== station.id) {
          IDs.push(current.id)
        }
        return IDs
      }, [])
      await api.updateStationsInsideCategory(
        category.id,
        category.type,
        stationIds
      )
      message.success(`${station.name} is removed from ${category.type}`)
      loadPageData()
    },
    []
  )

  return (
    <Spin spinning={loading.current}>
      <Title level={3}>Stations</Title>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        {data.current?.map((category) => (
          <Carousel
            key={category.id}
            name={category.type}
            onAddClick={() => onAddClick(category)}
          >
            {category.stations?.length ? (
              category.stations.map((item) => (
                <CarouselItem
                  key={item.id}
                  station={item}
                  categoryId={category.id}
                  onDrop={(drapId, dropId) =>
                    updateOrdering(drapId, dropId, category)
                  }
                  onRemove={(station) => handleRemoving(station, category)}
                />
              ))
            ) : (
              <span className={s.noData}> No data</span>
            )}
          </Carousel>
        ))}
      </DndProvider>
      <AddingModal
        visible={showAdding.current}
        category={selectedCategory.current}
        onDone={onModalDone}
        onCancel={onModalCancel}
      />
    </Spin>
  )
}

StationDiscovery.displayName = 'Discovery'

export default StationDiscovery
