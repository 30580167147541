import { Divider, Table, Spin, Input, Tooltip, Modal } from 'antd'
import { useEffect, useState, useCallback } from 'react'
import { getSocialAbuse } from 'src/api/social'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { search } from 'src/api/user'

const { Search } = Input
const { confirm } = Modal

const AbuseReason = () => {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(undefined)
  const [abuserUsername, setAbuserUsername] = useState(undefined)
  const [transformedData, setTransformedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)

  const transformData = async (data) => {
    if (data == null) {
      return null
    }
    return await Promise.all(
      data.map(async (item) => {
        return {
          ...item,
          username: item.user?.username,
          abuserUsername: item.abuser?.username,
          reportReasonTitle: item.reportReason?.title,
          reportReasonDesc: item.reportReason?.description,
          description: item.description,
        }
      })
    )
  }

  const handleTableChange = (pagination: any) => {
    const { current, pageSize } = pagination
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
    fetchBadContentData(current, pageSize, username, abuserUsername)
  }

  const fetchBadContentData = async (page, size, userId, abuserId) => {
    try {
      setLoading(true)
      const response = await getSocialAbuse(page - 1, size, userId, abuserId)
      const transformedData = await transformData(response.data)
      setTransformedData(transformedData)
      setTotalRecords(response.total_items)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = async (value) => {
    if (value !== '') {
      const user = await findUser(value)
      setUsername(user[0].user_id)
      setCurrentPage(1)
      setPageSize(10)
      fetchBadContentData(1, 10, user[0].user_id, abuserUsername)
    } else {
      setUsername('')
      setCurrentPage(1)
      setPageSize(10)
      fetchBadContentData(1, 10, value, abuserUsername)
    }
  }
  const handleSearchAbuser = async (value) => {
    if (value !== '') {
      const user = await findUser(value)
      setAbuserUsername(user[0].user_id)
      setCurrentPage(1)
      setPageSize(10)
      fetchBadContentData(1, 10, username, user[0].user_id)
    } else {
      setAbuserUsername('')
      setCurrentPage(1)
      setPageSize(10)
      fetchBadContentData(1, 10, username, '')
    }
  }

  const findUser = useCallback(async (value) => {
    if (!value) return
    setLoading(true)
    const res = await search({ username: value, size: 50 })
    setLoading(false)
    return res.data
  }, [])

  useEffect(() => {
    fetchBadContentData(currentPage, pageSize, username || '', '')
  }, [pageSize, currentPage, username])

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Abused User Name',
      dataIndex: 'abuserUsername',
      key: 'abuserUsername',
    },
    {
      title: 'Report Reason Title',
      dataIndex: 'reportReasonTitle',
      key: 'reportReasonTitle',
      width: 300,
      render: (text) => (
        <Tooltip title={text}>
          {text?.length > 20 ? text.substring(0, 20) + '...' : text}
        </Tooltip>
      ),
    },
    {
      title: 'Report Reason Description',
      dataIndex: 'reportReasonDesc',
      key: 'reportReasonDesc',
      width: 300,
      render: (text) => (
        <Tooltip title={text}>
          {text?.length > 20 ? text.substring(0, 20) + '...' : text}
        </Tooltip>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (text) => (
        <Tooltip title={text}>
          {text?.length > 20 ? text.substring(0, 20) + '...' : text}
        </Tooltip>
      ),
    },
  ]

  return (
    <>
      <Spin spinning={loading}>
        <Search
          placeholder="Search by username"
          onSearch={handleSearch}
          style={{ width: '16.5%', marginLeft: '15px' }}
          allowClear
        />
        <Search
          placeholder="Search by Abuser username"
          onSearch={handleSearchAbuser}
          style={{ width: '16.5%', marginLeft: '15px' }}
          allowClear
        />
        <Divider />
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={transformedData}
            columns={columns}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50', '100'],
              total: totalRecords,
            }}
            onChange={handleTableChange}
          />
        </DndProvider>
      </Spin>
    </>
  )
}

export default AbuseReason
