import { manualFetch } from 'src/utils/fetch'
import { useState, useEffect, useCallback } from 'react'
import { getSurvivalZone } from 'src/api/survivalzone'
import { Spin, Pagination } from 'antd'
import SurvivalZoneCard from './survivalzonecard'

const SectionSurvivalZone = ({ searchTerm }) => {
  const [survivalZone, setSurvivalZone] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const pageSize = 10

  const fetchImage = async (url) => {
    const response = await manualFetch(url, {
      headers: {
        accept: 'image/*',
      },
    })
    const imageBlob = await response.blob()
    return URL.createObjectURL(imageBlob)
  }

  const fetchData = useCallback(async (page, term) => {
    try {
      setDataLoaded(false)
      const data = await getSurvivalZone(page, pageSize, term)
      const survivalZoneData = await Promise.all(
        data.data.map(async (element) => {
          const imageUrl = await fetchImage(element.fullS3Path)
          const thumbnailImageUrl = await fetchImage(element.thumbnailS3Path)
          return {
            ...element,
            imageUrl,
            thumbnailImageUrl,
          }
        })
      )
      setSurvivalZone(survivalZoneData)
      setTotalItems(data.total_items)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setDataLoaded(true)
    }
  }, [])

  useEffect(() => {
    fetchData(currentPage - 1, searchTerm)
  }, [currentPage, searchTerm, fetchData])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div>
      {dataLoaded ? (
        <div>
          <div className="survival-zone-container">
            {survivalZone.map((guy) => (
              <SurvivalZoneCard
                key={guy.id}
                id={guy.id}
                img={guy.imageUrl}
                name={guy.name}
                nameFr={guy.nameFr}
                desc={guy.description}
                descFr={guy.descriptionFr}
                enabled={guy.enabled}
                instruction={guy.instruction}
                instructionFr={guy.instructionFr}
                title={guy.title}
                titleFr={guy.titleFr}
                category={guy.category}
                thumbnailImage={guy.thumbnailImageUrl}
              />
            ))}
          </div>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={handlePageChange}
            showSizeChanger={false}
            style={{ marginTop: 10 }}
          />
        </div>
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default SectionSurvivalZone
