import {
  Divider,
  Select,
  Table,
  Spin,
  Button,
  Input,
  message,
  Modal,
  Row,
  Upload,
  Col,
  Tooltip,
} from 'antd'
import { useEffect, useState, useCallback } from 'react'
import { getBotPost, deleteContentById, updateBotPost } from 'src/api/social'
import { getBots } from 'src/api/user'
import { DndProvider } from 'react-dnd'
import { manualFetch } from 'src/utils/fetch'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { fetchCountryProvider } from 'src/api/authen'
import {
  UploadOutlined,
  SmileOutlined,
  CommentOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import EmojiPicker from 'emoji-picker-react'

import { extractTextContent } from 'src/utils/stringUtil'
import { useNavigate } from 'react-router-dom'

const { confirm } = Modal

const BotPost = () => {
  const navigate = useNavigate()
  const [providerCountry, setProviderCountry] = useState('TN')
  const [countries, setCountries] = useState([])
  const [userData, setUserData] = useState([])
  const [userId, setUserId] = useState(undefined)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [editRecord, setEditRecord] = useState(null)
  const [editText, setEditText] = useState('')
  const [newAttachments, setNewAttachments] = useState([])
  const [deletedAttachments, setDeletedAttachments] = useState([])
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const params = new URLSearchParams()
  params.append('botUserId', userId)

  const handleClickOpen = (id) => {
    navigate(`/social/bot-post/comments/${id}?${params.toString()}`)
  }

  const [loading, setLoading] = useState(true)

  const [transformedData, setTransformedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)

  const [previewImage, setPreviewImage] = useState(null)
  const [previewVisible, setPreviewVisible] = useState(false)

  const fetchImage = useCallback((postid, attachmentId) => {
    const url = `${process.env.REACT_APP_API_URL}/api/social/posts/${postid}/file/${attachmentId}/download`
    return manualFetch(url, {
      headers: {
        accept: 'image/*',
      },
    })
      .then((response) => response.blob())
      .then((imageBlob) => {
        const imageUrl = URL.createObjectURL(imageBlob)
        return imageUrl
      })
  }, [])

  const transformData = async (data) => {
    if (data == null) {
      return null
    }
    const transformedArray = await Promise.all(
      data.map(async (item) => {
        const resourceContents = await getImages(item.attachments, item.id)
        return {
          ...item,
          key: item.id,
          createdOn: convertToCustomFormat(item.created_on),
          resourceContents: resourceContents,
        }
      })
    )
    return transformedArray
  }

  const handleInputChange = (e) => {
    setUserId(e)
  }

  const getImages = async (attachments, postId) => {
    const fileContents = []
    if (attachments == null) {
      return fileContents
    }
    for (const attachment of attachments) {
      fileContents.push({
        id: attachment.attachmentId,
        content: await fetchImage(postId, attachment.attachmentId),
      })
    }
    return fileContents
  }

  const handleDelete = async (record) => {
    try {
      setLoading(true)
      await deleteContentById(record, 'BOT-POST')
      message.success('Content deleted successfully!')

      const newTotalRecords = totalRecords - 1
      const newPage =
        currentPage > 1 && newTotalRecords % pageSize === 0
          ? currentPage - 1
          : currentPage

      await fetchBotPostData(newPage, pageSize, userId, providerCountry)
      setCurrentPage(newPage)
    } catch (error) {
      message.error('Failed to delete content.')
    } finally {
      setLoading(false)
    }
  }

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const fetchBotUser = async () => {
    const response = await getBots()
    const usersList = response.data.map((element) => ({
      label: element.username,
      value: element.user_id,
    }))
    setUserId(usersList[0].value)
    setUserData(usersList)
    return usersList
  }

  const fetchBotPostData = async (page, size, userId, providerCountry) => {
    try {
      setLoading(true)
      const response = await getBotPost(page - 1, size, providerCountry, userId)
      const transformedData = await transformData(response.data)
      setTransformedData(transformedData)
      setTotalRecords(response.total_items)
    } finally {
      setLoading(false)
    }
  }

  const handleProviderCountryChange = (value) => {
    setProviderCountry(value)
  }

  const showEditModal = (record) => {
    setEditRecord(record)
    setEditText(record.text)
    setNewAttachments([])
    setDeletedAttachments([])
    setEditModalVisible(true)
  }

  const handleAttachmentRemove = (attachment) => {
    setDeletedAttachments((prev) => [...prev, attachment.id])
    setEditRecord((prev) => ({
      ...prev,
      resourceContents: prev.resourceContents.filter(
        (item) => item.id !== attachment.id
      ),
    }))
  }

  const handleEditSave = async () => {
    if (!editRecord) return

    setLoading(true)
    try {
      const updatedData = {
        id: editRecord.id,
        text: editText,
        newAttachments,
        attachmentsToDelete: deletedAttachments?.map(
          (attachment) => attachment
        ),
      }

      await updateBotPost(updatedData)
      message.success('Post updated successfully!')
      setEditModalVisible(false)
      await fetchBotPostData(currentPage, pageSize, userId, providerCountry)
    } catch (error) {
      message.error('Failed to update post.')
    } finally {
      setLoading(false)
    }
  }

  const convertToCustomFormat = (timestamp) => {
    const date = new Date(timestamp)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
  }

  const fetchCountry = async () => {
    try {
      const data = await fetchCountryProvider()
      const countryOptions = data.data.map((item) => ({
        label: item.countryName,
        value: item.countryCode,
      }))
      setCountries(countryOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure you want to delete this content?',
      content: 'Once deleted, this action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk: () => handleDelete(record),
      onCancel: () => {
        message.info('Deletion cancelled.')
      },
    })
  }

  const handleImageClick = (imageUrl) => {
    setPreviewImage(imageUrl.content)
    setPreviewVisible(true)
  }

  useEffect(() => {
    if (!userId) return
    fetchBotPostData(currentPage, pageSize, userId, providerCountry)
  }, [currentPage, pageSize, userId, providerCountry])

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchCountry()
      const users = await fetchBotUser()

      if (users.length > 0) {
        setUserId(users[0].value)
      } else {
        fetchBotPostData(currentPage, pageSize, '', providerCountry)
      }
    }

    fetchInitialData()
  }, [])

  const handleFileChange = ({ fileList }) => {
    setNewAttachments(fileList)
  }

  const handleEmojiSelect = (emojiObject) => {
    const textarea = document.getElementById(
      'editTextArea'
    ) as HTMLTextAreaElement
    if (!textarea) return

    const start = textarea.selectionStart
    const end = textarea.selectionEnd

    setEditText((editText) => {
      const textBefore = editText.slice(0, start)
      const textAfter = editText.slice(end)

      return textBefore + emojiObject.emoji + textAfter
    })
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd =
        start + emojiObject.emoji.length
      textarea.focus()
    }, 0)
  }

  const columns = [
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      width: 300,
      render: (text) => (
        <Tooltip title={text}>
          {text?.length > 40 ? text.substring(0, 40) + '...' : text}
        </Tooltip>
      ),
    },
    {
      title: 'Attachments',
      dataIndex: 'resourceName',
      key: 'resourceName',
      width: 500,
      render: (_, record) => {
        if (record.resourceContents && record.resourceContents.length > 0) {
          return (
            <Row gutter={[16, 16]} justify="start">
              {record.resourceContents.map((content, index) => {
                return (
                  <Col key={index} span={6}>
                    <img
                      src={content.content}
                      alt="Attachment"
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        border: '1px solid #ddd',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                      }}
                      onClick={() => handleImageClick(content)}
                    />
                  </Col>
                )
              })}
            </Row>
          )
        }
        return null
      },
    },
    {
      title: 'Create Date',
      dataIndex: 'createdOn',
      key: 'createdOn',
      width: 200,
    },
    {
      title: 'Comments',
      key: 'View Comment',

      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => handleClickOpen(record.id)}
          disabled={record.comments === null}
        >
          <CommentOutlined />
        </Button>
      ),
    },
    {
      title: 'Edit',
      key: 'Edit',
      render: (_, record) => (
        <Button type="primary" onClick={() => showEditModal(record)}>
          <EditOutlined />
        </Button>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (_, record) => (
        <Button type="primary" danger onClick={() => showDeleteConfirm(record)}>
          <DeleteOutlined />
        </Button>
      ),
    },
  ]

  return (
    <>
      <Spin spinning={loading}>
        <Select
          placeholder="Select Country"
          value={providerCountry}
          onChange={handleProviderCountryChange}
          options={countries}
          style={{ marginLeft: '15px', width: '15%' }}
        />
        <Select
          placeholder="Username"
          allowClear
          options={userData}
          onChange={handleInputChange}
          value={userId}
          className="search-input"
          showSearch
          optionFilterProp="label"
          style={{ marginLeft: 10 }}
        />
        <Divider />
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={transformedData}
            columns={columns}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50', '100'],
              total: totalRecords,
            }}
            onChange={handleTableChange}
          />
        </DndProvider>
      </Spin>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        width="40%"
        style={{
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
        bodyStyle={{
          padding: 0,
          textAlign: 'center',
        }}
      >
        <img
          alt="Preview"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '70vh',
            objectFit: 'contain',
            borderRadius: '8px',
          }}
          src={previewImage}
        />
      </Modal>
      <Modal
        title="Edit Post"
        visible={editModalVisible}
        onOk={handleEditSave}
        onCancel={() => setEditModalVisible(false)}
        okText="Save"
      >
        <div style={{ position: 'relative' }}>
          <Input.TextArea
            id="editTextArea"
            rows={4}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            placeholder="Edit text here..."
          />
          <Button
            type="text"
            icon={<SmileOutlined />}
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 1,
            }}
          />

          {showEmojiPicker && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: '100%',
                marginTop: '5px',
                zIndex: 10,
                background: 'white',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </div>
          )}
        </div>

        <h4>Current Attachments:</h4>
        <Row gutter={[16, 16]}>
          {editRecord?.resourceContents?.map((attachment, index) => (
            <Col key={index} span={6}>
              <img
                src={attachment.content}
                alt="Attachment"
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
              <Button
                type="link"
                danger
                onClick={() => handleAttachmentRemove(attachment)}
              >
                Remove
              </Button>
            </Col>
          ))}
        </Row>

        <h4>Add New Attachments:</h4>
        <Upload
          multiple
          beforeUpload={() => false}
          fileList={newAttachments}
          onChange={handleFileChange}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Modal>
    </>
  )
}

export default BotPost
