import React, { useState } from "react";
import { Modal, Input, Select, Button, Radio, Checkbox, Space, message } from "antd";
import {useTriviaQuizCategoryAndType} from 'src/hooks/TriviaQuizCategoryAndTypeContext'
import { v4 as uuidv4 } from 'uuid';
interface Answer {
  id?: string;
  text: string;
}

interface Question {
  text: string;
  type: "SINGLE_CHOICE" | "MULTIPLE_CHOICE" | "TRUE_FALSE";
  category?: string;
  answers?: Answer[];
  correctAnswerIds?: string[];
  correctAnswer?: boolean;
}

interface Props {
  visible: boolean;
  onCancel: () => void;
  onCreate: (newQuestion: Question) => Promise<boolean>;
}
const initialQuestionState: Question = {
    text: "",
    type: null,
    category: "",
    answers: [],
    correctAnswerIds: [],
    
};
const CreateQuestion: React.FC<Props> = ({ visible, onCancel, onCreate }) => {
    const { categoryList, quizTypeList, isLoading, error } =useTriviaQuizCategoryAndType()
  const [question, setQuestion] = useState<Question>({
    text: "",
    type: null,
    category: "",
    answers: [],
    correctAnswerIds: [],
  });

  const handleInputChange = (field: keyof Question, value: any) => {
    setQuestion(prev => ({ ...prev, [field]: value }));
  };

  const addAnswer = () => {
    setQuestion(prev => ({
      ...prev,
      answers: [...(prev.answers || []), { text: "" }],
    }));
  };

  const removeAnswer = (index: number) => {
    setQuestion(prev => {
      const updatedAnswers = [...(prev.answers || [])];
      const removedAnswer = updatedAnswers.splice(index, 1)[0];

      const updatedCorrectAnswerIds = (prev.correctAnswerIds || []).filter(
          id => id !== removedAnswer?.id
      );

      return { ...prev, answers: updatedAnswers, correctAnswerIds: updatedCorrectAnswerIds };
    });
  };

  const updateAnswerText = (index: number, text: string) => {
    setQuestion(prev => {
      const updatedAnswers = [...(prev.answers || [])];
      updatedAnswers[index].text = text;
      updatedAnswers[index].id = uuidv4();
      return { ...prev, answers: updatedAnswers };
    });
  };

  const handleCorrectAnswerChange = (value: string | string[]) => {
    if (question.type === "MULTIPLE_CHOICE") {
      setQuestion(prev => ({ ...prev, correctAnswerIds: value as string[] }));
    } else {
      setQuestion(prev => ({ ...prev, correctAnswerIds: [value as string] }));
    }
  };

  const handleSubmit = () => {
   
    onCreate(question).then(success => { 
      
      if (success) {
        setQuestion(initialQuestionState);
      }
    });
    
  };
  const handleCancel =() => {
   setQuestion(initialQuestionState)
    
    onCancel()
  }

  return (
    <Modal
      visible={visible}
      title="Create New Question"
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText="Create"
      cancelText="Cancel"
    >
      <Input.TextArea
        value={question.text}
        onChange={e => handleInputChange("text", e.target.value)}
        placeholder="Enter question text"
        rows={2}
      />

      <Select
        value={question.category || null} 
        onChange={value => handleInputChange("category", value)}
        style={{ width: "100%", marginTop: 10 }}
        placeholder="Select category"
      >
        {categoryList.map(category => (
          <Select.Option key={category} value={category}>
            {category}
          </Select.Option>
        ))}
      </Select>

      <Select
        value={question.type || null}
        onChange={value => handleInputChange("type", value)}
        style={{ width: "100%", marginTop: 10 }}
        placeholder="Select Quiz Type"
      >
        {quizTypeList.map(type => (
            <Select.Option key={type} value ={type}>
                {type}
            </Select.Option>
        ))}
      </Select>

      {question.type !==null && question.type !== "TRUE_FALSE" && (
        <>
          <h4 style={{ marginTop: 10 }}>Answers</h4>
          {question.answers?.map((answer, index) => (
            <Space key={index} style={{ width: "100%", display: "flex", marginBottom: 5 }}>
              <Input
                value={answer.text}
                onChange={e => updateAnswerText(index, e.target.value)}
                placeholder={`Answer ${index + 1}`}
                style={{ flex: 1 }}
              />
              <Button danger onClick={() => removeAnswer(index)}>Remove</Button>
            </Space>
          ))}
          <Button type="dashed" onClick={addAnswer} style={{ marginTop: 5 }}>
            Add Answer
          </Button>
        </>
      )}

      {question.type !==null &&<h4 style={{ marginTop: 10 }}>Correct Answer</h4>}
      {question.type === "MULTIPLE_CHOICE" && (
        <Checkbox.Group onChange={(checkedValues: string[]) => { 
            handleCorrectAnswerChange(checkedValues); 
        }}>
            <Space direction="vertical">
            {question.answers?.map(ans => (
              <Checkbox key={ans.text} value={ans.id}>
                {ans.text}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
        
      )}
      {question.type === "SINGLE_CHOICE" && (
        <Radio.Group onChange={e => handleCorrectAnswerChange(e.target.value)}>
          <Space direction="vertical">
            {question.answers?.map(ans => (
              <Radio key={ans.text} value={ans.id}>
                {ans.text}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )}
      {question.type === "TRUE_FALSE" && (
        <Radio.Group onChange={e => handleInputChange("correctAnswer", e.target.value)}>
          <Space direction="horizontal">
            <Radio value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Space>
        </Radio.Group>
      )}
    </Modal>
  );
};

export default CreateQuestion;
