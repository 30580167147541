import {
  Divider,
  Input,
  Typography,
  Button,
  Upload,
  message,
  Select,
  Spin,
} from 'antd'
import { useState, useEffect, useRef } from 'react'

import {
  UploadOutlined,
  DeleteOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import { getBots } from 'src/api/user'
import { savePost } from 'src/api/social'
import { fetchCountryProvider } from 'src/api/authen'
import EmojiPicker from 'emoji-picker-react'

const { Title } = Typography

const Post = () => {
  const [postContent, setPostContent] = useState('')
  const [attachments, setAttachments] = useState<any[]>([])
  const [userData, setUserData] = useState([])
  const [userId, setUserId] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const newImages = useRef<File[]>([])
  const [countries, setCountries] = useState([])
  const [country, setCountry] = useState('TN')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const handleInputChange = (e) => {
    setUserId(e)
  }

  const handlePostChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPostContent(e.target.value)
  }

  const handleDeleteImage = (index: number) => {
    const file = attachments.filter((_, i) => i !== index)
    newImages.current = newImages.current.filter((_, i) => i !== index)
    setAttachments(file)
  }

  const handleUploadChange = (info: any) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setAttachments([...attachments, URL.createObjectURL(info.file)])
      newImages.current = [...newImages.current, info.file]
    }
    reader.readAsDataURL(info.file)
  }
  const fetchCountry = async () => {
    try {
      const data = await fetchCountryProvider()
      const countryOptions = data.data.map((item) => ({
        label: item.countryName,
        value: item.countryCode,
      }))
      setCountries(countryOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleSubmit = async () => {
    if (postContent.trim() || attachments.length) {
      setLoading(true)
      try {
        const response = await savePost(
          country,
          userId,
          postContent,
          newImages.current
        )
        message.success('Post submitted successfully!')
        setAttachments([])
        newImages.current = []
        setPostContent('')
      } catch (error) {
        console.log('error', error)
        message.error(
          error.meta_data.message +
            ' Valid Format :  jpg,jpeg,png,gif,bmp,tiff,webp,mp4,avi,mkv,mov,flv,wmv,webm'
        )
      } finally {
        setLoading(false)
      }
    } else {
      message.warning(
        'Please add some content or an attachment before submitting!'
      )
    }
  }

  const fetchBotUser = async () => {
    const response = await getBots()
    const usersList = response.data.map((element) => ({
      label: element.username,
      value: element.user_id,
    }))
    setUserId(usersList[0].value)
    setUserData(usersList)
  }

  const handleCountryChange = (value) => {
    setCountry(value)
  }
  const handleEmojiSelect = (emojiObject) => {
    const textarea = document.getElementById(
      'editTextArea'
    ) as HTMLTextAreaElement
    if (!textarea) return

    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    setPostContent((prevContent) => {
      const textBefore = prevContent.slice(0, start)
      const textAfter = prevContent.slice(end)

      return textBefore + emojiObject.emoji + textAfter
    })
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd =
        start + emojiObject.emoji.length
      textarea.focus()
    }, 0)
  }

  useEffect(() => {
    fetchBotUser()
    fetchCountry()
  }, [])

  return (
    <Spin spinning={loading} tip="Submitting...">
      <div style={{ padding: '16px', position: 'relative' }}>
        <Title level={3}>Create a Bot Post</Title>
        <Divider />
        <Select
          placeholder="Select Country"
          value={country}
          onChange={handleCountryChange}
          options={countries}
          style={{ width: '12%' }}
        />
        <Select
          placeholder="Username"
          allowClear
          options={userData}
          onChange={handleInputChange}
          value={userId}
          className="search-input"
          showSearch
          optionFilterProp="label"
          style={{ marginLeft: 10 }}
        />
        <div style={{ position: 'relative' }}>
          <Input.TextArea
            id="editTextArea"
            ref={textAreaRef}
            rows={4}
            placeholder="Write your post here..."
            value={postContent}
            onChange={handlePostChange}
            style={{ marginBottom: '16px', paddingRight: '32px' }}
          />
          <Button
            type="text"
            icon={<SmileOutlined />}
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 1,
            }}
          />
          {showEmojiPicker && (
            <div style={{ position: 'absolute', zIndex: 2, right: 0, top: 40 }}>
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </div>
          )}
        </div>
        <Upload
          beforeUpload={() => false}
          showUploadList={false}
          name="file"
          multiple
          onChange={handleUploadChange}
          style={{ marginBottom: '16px' }}
        >
          <Button icon={<UploadOutlined />}>Attach Files</Button>
        </Upload>
        <br></br>
        <div className="inline-form-item image-row">
          <div className="images-wrapper">
            {attachments.map((imageUrl, index) => (
              <div key={index} className="image-container">
                <img
                  src={imageUrl}
                  className="card-image"
                  alt={`Image ${index}`}
                />
                <DeleteOutlined
                  onClick={() => handleDeleteImage(index)}
                  className="delete-icon1"
                />
              </div>
            ))}
          </div>
        </div>

        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Spin>
  )
}

export default Post
