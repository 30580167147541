import {
  UnorderedListOutlined,
  LogoutOutlined,
  TagsOutlined,
  PlayCircleOutlined,
  NotificationOutlined,
  AppstoreOutlined,
  FileImageOutlined,
  UserOutlined,
  GlobalOutlined,
  RobotOutlined,
  ShopOutlined,
  HeatMapOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
  TrophyOutlined,
  SmileOutlined,
  TeamOutlined,
  ReconciliationOutlined,
  TwitterOutlined,
  DollarOutlined,
} from '@ant-design/icons'
import { Menu, Layout } from 'antd'
import { useState, useMemo, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { deleteToken } from 'src/utils/accessToken'
import AppContext from 'src/hooks/AppContext'

const { Sider } = Layout

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { userInfo } = useContext(AppContext)

  const getSelected = () => {
    const [, pathname] = location.pathname.split('/')
    return `/${pathname}`
  }

  const onLogout = () => {
    deleteToken()
    navigate('/login')
  }

  const items = useMemo(() => {
    const allItems = [
      {
        key: '/discovery',
        icon: <AppstoreOutlined />,
        label: 'Discovery',
        requiredprivileges: ['music', 'unity', 'superadmin'],
        children: [
          {
            key: '/discovery/playlist-discovery',
            icon: <UnorderedListOutlined />,
            label: <Link to="/discovery/playlist-discovery">Playlists</Link>,
            requiredprivileges: ['music', 'superadmin'],
          },
          {
            key: '/discovery/tracks-discovery',
            icon: <UnorderedListOutlined />,
            label: <Link to="/discovery/tracks-discovery">Tracks</Link>,
            requiredprivileges: ['music', 'superadmin'],
          },
          {
            key: '/discovery/stations-discovery',
            icon: <UnorderedListOutlined />,
            label: <Link to="/discovery/stations-discovery">Stations</Link>,
            requiredprivileges: ['music', 'superadmin'],
          },
          {
            key: '/discovery/games-discovery',
            icon: <UnorderedListOutlined />,
            label: <Link to="/discovery/games-discovery">Games</Link>,
            requiredprivileges: ['unity', 'superadmin'],
          },
          {
            key: '/discovery/metaverse-discovery',
            icon: <UnorderedListOutlined />,
            label: <Link to="/discovery/metaverse-discovery">Metaverse</Link>,
            requiredprivileges: ['unity', 'superadmin'],
          },
          {
            key: '/discovery/maze-discovery',
            icon: <UnorderedListOutlined />,
            label: <Link to="/discovery/maze-discovery">Maze</Link>,
            requiredprivileges: ['unity', 'superadmin'],
          },
        ],
      },
      {
        key: '/playlist',
        icon: <UnorderedListOutlined />,
        label: <Link to="/playlist">Playlist</Link>,
        requiredprivileges: ['music', 'superadmin'],
      },
      {
        key: '/metaverse',
        icon: <GlobalOutlined />,
        label: <Link to="/metaverse">Metaverse</Link>,
        requiredprivileges: ['unity', 'superadmin'],
      },
      {
        key: '/games',
        icon: <RobotOutlined />,
        label: <Link to="/games">Games</Link>,
        requiredprivileges: ['unity', 'superadmin'],
      },
      {
        key: '/survival-zone',
        icon: <SmileOutlined />,
        label: <Link to="/survival-zone">Survival Zone</Link>,
        requiredprivileges: ['unity', 'superadmin'],
      },
      {
        key: '/treasure-hunter',
        icon: <DollarOutlined />,
        label: <Link to="/treasure-hunter">Treasure Hunter</Link>,
        requiredprivileges: ['unity', 'superadmin'],
      },
      {
        key: '/marketplace',
        icon: <ShopOutlined />,
        label: <Link to="/marketplace">Marketplace</Link>,
        requiredprivileges: ['marketplace', 'superadmin'],
      },
      {
        key: '/maze',
        icon: <HeatMapOutlined />,
        label: <Link to="/maze">Maze</Link>,
        requiredprivileges: ['unity', 'superadmin'],
      },
      {
        key: '/faq',
        icon: <QuestionCircleOutlined />,
        label: <Link to="/faq">Faq</Link>,
        requiredprivileges: ['users', 'superadmin'],
      },
      {
        key: '/abuse',
        icon: <WarningOutlined />,
        label: <Link to="/abuse">Abuse</Link>,
        requiredprivileges: ['game', 'superadmin'],
      },
      {
        key: '/track',
        icon: <PlayCircleOutlined />,
        label: <Link to="/track">Track</Link>,
        requiredprivileges: ['music', 'superadmin'],
      },
      {
        key: '/station',
        icon: <NotificationOutlined />,
        label: <Link to="/station">Station</Link>,
        requiredprivileges: ['music', 'superadmin'],
      },
      {
        key: '/tag',
        icon: <TagsOutlined />,
        label: <Link to="/tag">Tag</Link>,
        requiredprivileges: ['music', 'superadmin'],
      },
      {
        key: '/image',
        icon: <FileImageOutlined />,
        label: 'Image',
        requiredprivileges: ['music', 'superadmin'],
        children: [
          {
            key: '/image/playlist',
            icon: <UnorderedListOutlined />,
            label: <Link to="/image/playlist">Playlist</Link>,
            requiredprivileges: ['music', 'superadmin'],
          },
        ],
      },
      {
        key: '/users',
        icon: <UserOutlined />,
        label: <Link to="/users">Users</Link>,
        requiredprivileges: ['users', 'superadmin'],
      },
      {
        key: '/redeemUsers',
        icon: <TrophyOutlined />,
        label: <Link to="/redeemUsers">Redeem Users</Link>,
        requiredprivileges: ['marketplace', 'superadmin'],
      },
      {
        key: '/audit',
        icon: <ReconciliationOutlined />,
        label: <Link to="/audit">Audit</Link>,
        requiredprivileges: ['superadmin'],
      },
      {
        key: '/social',
        icon: <TwitterOutlined />,
        label: 'Social',
        requiredprivileges: ['superadmin', 'social'],
        children: [
          {
            key: '/social/post',
            icon: <UnorderedListOutlined />,
            label: <Link to="/social/post">Post</Link>,
            requiredprivileges: ['superadmin', 'social'],
          },
          {
            key: '/social/message',
            icon: <UnorderedListOutlined />,
            label: <Link to="/social/message">Message</Link>,
            requiredprivileges: ['superadmin', 'social'],
          },
          {
            key: '/social/bot-posts',
            icon: <UnorderedListOutlined />,
            label: <Link to="/social/bot-posts">Bot Posts</Link>,
            requiredprivileges: ['superadmin', 'social'],
          },
          {
            key: '/social/user-posts',
            icon: <UnorderedListOutlined />,
            label: <Link to="/social/user-posts">User Posts</Link>,
            requiredprivileges: ['superadmin', 'social'],
          },
          {
            key: '/social/user-comments',
            icon: <UnorderedListOutlined />,
            label: <Link to="/social/user-comments">User Comments</Link>,
            requiredprivileges: ['superadmin', 'social'],
          },
          {
            key: '/social/abuse-reason',
            icon: <UnorderedListOutlined />,
            label: <Link to="/social/abuse-reason">Abuse Reason</Link>,
            requiredprivileges: ['superadmin', 'social'],
          },
        ],
      },
      {
        key: '/access',
        icon: <TeamOutlined />,
        label: <Link to="/access">Access</Link>,
        requiredprivileges: ['superadmin'],
      },
      {
        key: '/quiz',
        icon: <ReconciliationOutlined />,
        label: <Link to="/quiz">Quiz</Link>,
        requiredprivileges: ['unity', 'superadmin'],
      },
      {
        key: '/reports',
        icon: <FileImageOutlined />,
        label: 'Report',
        requiredprivileges: ['report', 'superadmin'],
        children: [
          {
            key: '/reports/playlist-reports',
            icon: <UnorderedListOutlined />,
            label: <Link to="/reports/playlist-reports">Playlists Report</Link>,
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/station-reports',
            icon: <UnorderedListOutlined />,
            label: <Link to="/reports/station-reports">Stations Report</Link>,
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/tracks-reports',
            icon: <UnorderedListOutlined />,
            label: <Link to="/reports/tracks-reports">Tracks Report</Link>,
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/appUsage-reports',
            icon: <UnorderedListOutlined />,
            label: <Link to="/reports/appUsage-reports">App Usage Report</Link>,
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/metaverse-reports',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/reports/metaverse-reports">Metaverse Report</Link>
            ),
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/maze-reports',
            icon: <UnorderedListOutlined />,
            label: <Link to="/reports/maze-reports">Maze Report</Link>,
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/survivor-zone-report',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/reports/survivor-zone-report">
                Survivor Zone Report
              </Link>
            ),
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/usersSearch-reports',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/reports/usersSearch-reports">Users Search Report</Link>
            ),
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/userProfileChange-reports',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/reports/userProfileChange-reports">
                Profile Change Report
              </Link>
            ),
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/gameUsage-reports',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/reports/gameUsage-reports">Game Usage Report</Link>
            ),
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/chatUsage-reports',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/reports/chatUsage-reports">Chat Usage Report</Link>
            ),
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/socialUsage-reports',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/reports/socialUsage-reports">Social Usage Report</Link>
            ),
            requiredprivileges: ['report', 'superadmin'],
          },
          {
            key: '/reports/treasureHunter-reports',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/reports/treasureHunter-reports">
                Treasure Hunter Report
              </Link>
            ),
            requiredprivileges: ['report', 'superadmin'],
          },
        ],
      },
      {
        key: '/logout',
        icon: <LogoutOutlined />,
        onClick: onLogout,
        label: 'Logout',
      },
    ]

    const filterItems = (items) => {
      return items
        .filter(
          (item) =>
            !item.requiredprivileges ||
            (userInfo?.permissions &&
              item.requiredprivileges.some((privilege) =>
                userInfo.permissions.includes(privilege)
              ))
        )
        .map((item) => ({
          ...item,
          children: item.children ? filterItems(item.children) : undefined,
        }))
    }
    return filterItems(allItems)
  }, [userInfo])

  return (
    <Sider
      className="site-sidebar"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className="user">Welcome {userInfo?.name}</div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[getSelected()]}
        items={items}
      />
    </Sider>
  )
}
