import { message } from 'antd'
import { omit, pick } from 'ramda'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
import { saveAuditData } from 'src/api/audit'

export const getAll = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))
  const body = omit(['page', 'pageSize'], params)
  try {
    const { data } = await fetch(`/user-profile/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    return data
  } catch (e) {
    message.error('getAll user error')
  }
}

export const changeUserStatus = async (id, status) => {
  try {
    const response = fetch(`/auth/user/${id}/active`, {
      method: 'PATCH',
      body: JSON.stringify({
        active_status: status,
      }),
    })
    saveAuditData('EDIT', 'MONTHLYMARKETPLACE', id)
    return response
  } catch (error) {
    message.error('user status change error')
  }
}

export const search = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'pageSize'], params))
  const body = omit(['page', 'pageSize'], params)
  // if (Object.keys(body).length === 0) {
  //   message.error('No searching query !')
  //   return
  // }

  try {
    const { data } = await fetch(`/user-profile/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    return data
  } catch (e) {
    message.error('search User error !')
  }
}

export const getUsers = async (page, size, username = '', providerCountry) => {
  const requestBody: {
    username?: string
    providerCountry: string
  } = {
    providerCountry,
  }

  if (username !== '') {
    requestBody.username = username
  }

  try {
    const { data } = await fetch(
      `/user-profile/search?page=${page}&size=${size} `,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
      }
    )
    return data
  } catch (e) {
    message.error('Get All Users error !')
  }
}
export const getBots = async () => {
  const { data } = await fetch(`/user-profile/bots`)
  return data
}
