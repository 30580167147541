import React, { useReducer, useEffect, useState } from "react";
import { Tabs, Spin, Pagination, Button, message, Modal, Space } from "antd";
import SingleAnswerQuiz from "./SingleAnswerQuiz";
import MultiAnswerQuiz from "./MultiAnswerQuiz";
import { createTriviaQuestion, deleteTriviaQuestionAPI, fetchTriviaQuestionsAPI, searchTriviaQuestion, updateTriviaQuestion, validateQuestion } from 'src/api/metaverse';
import TrueFalseQuiz from './TrueFalseQuiz';
import CreateQuestion from './CreateQuizQuestion';
import SearchQuestion from './SearchQuizQuestion';

const QUIZ_TYPES = {
  SINGLE: "SINGLE_CHOICE",
  MULTIPLE: "MULTIPLE_CHOICE",
  TRUE_FALSE: "TRUE_FALSE"
};

interface State {
  questions: Record<string, Question[]>; 
  loading: boolean;
  totalItems: Record<string, number>; 
  currentPage: Record<string, number>; 
  pageSize: Record<string, number>; 
}

const initialState: State = {
  questions: { SINGLE_CHOICE: [], MULTIPLE_CHOICE: [], TRUE_FALSE: [] },
  loading: true,
  totalItems: { SINGLE_CHOICE: 0, MULTIPLE_CHOICE: 0, TRUE_FALSE: 0 },
  currentPage: { SINGLE_CHOICE: 1, MULTIPLE_CHOICE: 1, TRUE_FALSE: 1 },
  pageSize: { SINGLE_CHOICE: 10, MULTIPLE_CHOICE: 10, TRUE_FALSE: 10 }
};

function quizReducer(state: State, action: any): State {
  switch (action.type) {
    case "SET_QUESTIONS":
      return {
        ...state,
        questions: { ...state.questions, [action.payload.type]: action.payload.data },
        totalItems: { ...state.totalItems, [action.payload.type]: action.payload.total_items },
        loading: false
      };
    case "SET_LOADING":
      return { ...state, loading: true };
    case "SET_PAGE":
      return {
        ...state,
        currentPage: { ...state.currentPage, [action.payload.type]: action.payload.page },
        pageSize: { ...state.pageSize, [action.payload.type]: action.payload.pageSize }
      };
    case "RESET_PAGINATION":
      return {
        ...state,
        currentPage: { ...state.currentPage, [action.payload.type]: 1 }
      };
    default:
      return state;
  }
}

const QuizPage: React.FC = () => {
  const [state, dispatch] = useReducer(quizReducer, initialState);
  const [activeTab, setActiveTab] = useState(QUIZ_TYPES.SINGLE); 
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false); 
  const [isSearchActive, setIsSearchActive] = useState(false); 
  const [searchCriteria, setSearchCriteria] = useState<SearchQuestion | null>(null);

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
    setIsSearchActive(false)
    dispatch({ type: "RESET_PAGINATION", payload: { type: newTab } }); 
  };

  
  useEffect(() => {
    const fetchQuestions = async () => {
      dispatch({ type: "SET_LOADING" });
      try {
        if(!isSearchActive){
        const data = await fetchTriviaQuestionsAPI(
          activeTab,
          state.currentPage[activeTab] - 1, 
          state.pageSize[activeTab]
        );
        dispatch({
          type: "SET_QUESTIONS",
          payload: { type: activeTab, ...data }
        });
      }
      else {
        
        const data = await searchTriviaQuestion(
          searchCriteria,
          state.currentPage[activeTab] - 1, 
          state.pageSize[activeTab]
        );
        dispatch({
          type: "SET_QUESTIONS",
          payload: { type: activeTab, ...data }
        });
      }
      } catch (err) {
        console.error("Failed to load questions:", err);
      }
    };

    fetchQuestions();
  }, [activeTab, state.currentPage[activeTab], state.pageSize[activeTab]]);
  const handleCreateQuestion = async (newQuestion: Question) :Promise<boolean> => {
    const errorMessage = validateQuestion(newQuestion);
    if (errorMessage) {
       message.error(errorMessage);
      return false;
    }
    try {
      await createTriviaQuestion(newQuestion);
      setIsModalVisible(false);
      fetchTriviaQuestionsAPI(activeTab, state.currentPage[activeTab] - 1, state.pageSize[activeTab]).then(data => {
        dispatch({ type: "SET_QUESTIONS", payload: { type: activeTab, ...data } });
      });
    } catch (error) {
      console.error("Error creating question:", error);
    }
    return true
  };

  const handleUpdateQuestion = async (newQuestion: Question) => {
    const errorMessage = validateQuestion(newQuestion);
    if (errorMessage) {
       message.error(errorMessage);
      return;
    }
    try {
      await updateTriviaQuestion(newQuestion.id,newQuestion);
      setIsModalVisible(false);
      fetchTriviaQuestionsAPI(activeTab, state.currentPage[activeTab] - 1, state.pageSize[activeTab]).then(data => {
        dispatch({ type: "SET_QUESTIONS", payload: { type: activeTab, ...data } });
      });
    } catch (error) {
      console.error("Error creating question:", error);
    }
  };

  const handleDeleteQuestion = (questionId: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this question?",
      content: "This action cannot be undone.",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteTriviaQuestionAPI(questionId); 
          
          const newPage = state.questions[activeTab].length > 1
              ? state.currentPage[activeTab] - 1
              : state.currentPage[activeTab] - 2;
          fetchTriviaQuestionsAPI(activeTab, newPage, state.pageSize[activeTab]).then(data => {
            dispatch({ type: "SET_QUESTIONS", payload: { type: activeTab, ...data } });
            
            dispatch({ type: "SET_PAGE", payload: { type: activeTab, page:newPage+1,pageSize: state.pageSize[activeTab] } })
          });
        } catch (error) {
          console.error("Error deleting question:", error);
          message.error("Failed to delete question");
        }
      },
    });
  };
  const handleSearchQuestion = async (searchDto: SearchQuestion) :Promise<boolean> => {
   
    try {
      
      setSearchCriteria(searchDto)
      setIsSearchActive(true)
      setIsSearchModalVisible(false);
      searchDto.type = activeTab
      searchTriviaQuestion(searchDto, state.currentPage[activeTab] - 1, state.pageSize[activeTab]).then(data => {
        dispatch({ type: "SET_QUESTIONS", payload: { type: activeTab, ...data } });
      });
    } catch (error) {
      console.error("Error searching question:", error);
    }
    return true
  };

  if (state.loading) return <Spin size="large" />;

  return (
    <div>
       
       <Space>
    <Button type="primary" onClick={() => setIsModalVisible(true)}>
      Create New Question
    </Button>
    <Button type="primary" onClick={() => setIsSearchModalVisible(true)}>
      Search Question
    </Button>
  </Space>
      <SearchQuestion
      visible={isSearchModalVisible} 
      onCancel={() => setIsSearchModalVisible(false)} 
      onCreate={handleSearchQuestion} 
      />
      <CreateQuestion 
        visible={isModalVisible} 
        onCancel={() => setIsModalVisible(false)} 
        onCreate={handleCreateQuestion} 
      />
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <Tabs.TabPane tab="Single Answer" key={QUIZ_TYPES.SINGLE}>
          <SingleAnswerQuiz questions={state.questions[QUIZ_TYPES.SINGLE]} dispatch={dispatch} 
          onUpdate={handleUpdateQuestion} onDelete={handleDeleteQuestion}/>
          <Pagination
            current={state.currentPage[QUIZ_TYPES.SINGLE]}
            total={state.totalItems[QUIZ_TYPES.SINGLE]}
            pageSize={state.pageSize[QUIZ_TYPES.SINGLE]}
            showSizeChanger
            pageSizeOptions={["5", "10", "25", "50"]}
            onChange={(page, size) =>
              dispatch({ type: "SET_PAGE", payload: { type: QUIZ_TYPES.SINGLE, page, pageSize: size } })
            }
            style={{ marginTop: 20, textAlign: "center" }}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Multiple Choice" key={QUIZ_TYPES.MULTIPLE}>
          <MultiAnswerQuiz questions={state.questions[QUIZ_TYPES.MULTIPLE]} dispatch={dispatch} 
          onUpdate={handleUpdateQuestion} onDelete={handleDeleteQuestion}/>
          <Pagination
            current={state.currentPage[QUIZ_TYPES.MULTIPLE]}
            total={state.totalItems[QUIZ_TYPES.MULTIPLE]}
            pageSize={state.pageSize[QUIZ_TYPES.MULTIPLE]}
            showSizeChanger
            pageSizeOptions={["5", "10", "25", "50"]}
            onChange={(page, size) =>
              dispatch({ type: "SET_PAGE", payload: { type: QUIZ_TYPES.MULTIPLE, page, pageSize: size } })
            }
            style={{ marginTop: 20, textAlign: "center" }}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="True/False" key={QUIZ_TYPES.TRUE_FALSE}>
          <TrueFalseQuiz questions={state.questions[QUIZ_TYPES.TRUE_FALSE]} dispatch={dispatch}
          onUpdate={handleUpdateQuestion} onDelete={handleDeleteQuestion} />
          <Pagination
            current={state.currentPage[QUIZ_TYPES.TRUE_FALSE]}
            total={state.totalItems[QUIZ_TYPES.TRUE_FALSE]}
            pageSize={state.pageSize[QUIZ_TYPES.TRUE_FALSE]}
            showSizeChanger
            pageSizeOptions={["5", "10", "25", "50"]}
            onChange={(page, size) =>
              dispatch({ type: "SET_PAGE", payload: { type: QUIZ_TYPES.TRUE_FALSE, page, pageSize: size } })
            }
            style={{ marginTop: 20, textAlign: "center" }}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default QuizPage;
