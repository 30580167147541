import {
  Divider,
  Input,
  Typography,
  Button,
  Upload,
  message,
  Select,
  Spin,
  AutoComplete,
} from 'antd'
import { useState, useEffect, useRef } from 'react'
import { UploadOutlined, SmileOutlined } from '@ant-design/icons'
import { getBots } from 'src/api/user'
import { saveMessage, saveMessageUser } from 'src/api/social'
import { getUsers } from 'src/api/user'

import { fetchCountryProvider } from 'src/api/authen'
import EmojiPicker from 'emoji-picker-react'

const { Title } = Typography

const Message = () => {
  const [postContent, setPostContent] = useState('')
  const [attachments, setAttachments] = useState<any[]>([])
  const [botUserData, setBotUserData] = useState([])
  const [userData, setUserData] = useState([])

  const [username, setUsername] = useState('')
  const [botUserId, setBotUserId] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const newImages = useRef<File[]>([])
  const [countries, setCountries] = useState([])
  const [country, setCountry] = useState('TN')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [userId, setUserId] = useState(undefined)
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const allowedFileTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ]

  const handleInputChange = (e) => {
    setBotUserId(e)
  }

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPostContent(e.target.value)
  }

  const handleUploadChange = (info: any) => {
    const file = info.file
    const isAllowedType = allowedFileTypes.includes(file.type)

    if (isAllowedType) {
      const newAttachment = {
        name: file.name || 'Unknown File',
        type: file.type || '',
        url: URL.createObjectURL(file),
      }
      setAttachments([newAttachment])
      newImages.current = [file]
    } else {
      message.error(
        'Invalid file type. Only images, PDFs, Word, Excel, and PowerPoint files are allowed.'
      )
    }
  }

  const handleSubmit = async () => {
    if (postContent.trim() || attachments.length) {
      setLoading(true)
      try {
        if (userId === undefined) {
          await saveMessage(country, botUserId, postContent, newImages.current)
        } else {
          await saveMessageUser(
            userId,
            botUserId,
            postContent,
            newImages.current
          )
        }
        message.success('Message submitted successfully!')
        setAttachments([])
        newImages.current = []
        setPostContent('')
        setSearchTerm('')
        setUserId(undefined)
      } catch (error) {
        message.error('Failed to submit Message!')
      } finally {
        setLoading(false)
      }
    } else {
      message.warning(
        'Please add some content or an attachment before submitting!'
      )
    }
  }

  const handleCountryChange = (value) => {
    setCountry(value)
    setSearchTerm('')
    setUserId(undefined)
    setUserData([])
    setPage(0)
    setHasMore(true)
  }

  const fetchBotUser = async () => {
    const response = await getBots()
    const usersList = response.data.map((element) => ({
      label: element.username,
      value: element.user_id,
    }))
    setBotUserId(usersList[0].value)
    setBotUserData(usersList)
  }

  const fetchUsers = async (searchValue = '', reset = false) => {
    if (loadingUsers || (!reset && !hasMore)) return

    setLoadingUsers(true)

    try {
      const currentPage = reset ? 0 : page
      const response = await getUsers(currentPage, 10, searchValue, country)

      if (response.data.length === 0) {
        setHasMore(false)
      }

      const usersList = response.data.map((element) => ({
        label: element.username,
        value: element.user_id,
      }))

      setUserData((prevUsers) => {
        const mergedUsers = reset ? usersList : [...prevUsers, ...usersList]
        return Array.from(
          new Map(mergedUsers.map((user) => [user.value, user])).values()
        )
      })

      setPage(reset ? 1 : currentPage + 1)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoadingUsers(false)
    }
  }

  const fetchCountry = async () => {
    try {
      const data = await fetchCountryProvider()
      const countryOptions = data.data.map((item) => ({
        label: item.countryName,
        value: item.countryCode,
      }))
      setCountries(countryOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleEmojiSelect = (emojiObject) => {
    const textarea = document.getElementById(
      'editTextArea'
    ) as HTMLTextAreaElement
    if (!textarea) return

    const start = textarea.selectionStart
    const end = textarea.selectionEnd

    setPostContent((prevContent) => {
      const textBefore = prevContent.slice(0, start)
      const textAfter = prevContent.slice(end)

      return textBefore + emojiObject.emoji + textAfter
    })
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd =
        start + emojiObject.emoji.length
      textarea.focus()
    }, 0)
  }

  useEffect(() => {
    fetchUsers(searchTerm, true)
  }, [country])

  useEffect(() => {
    fetchBotUser()
    fetchCountry()
  }, [])

  return (
    <Spin spinning={loading} tip="Submitting...">
      <div style={{ padding: '16px', position: 'relative' }}>
        <Title level={3}>Create a Bot Message</Title>
        <Divider />
        <Select
          placeholder="Select Country"
          value={country}
          onChange={handleCountryChange}
          options={countries}
          style={{ width: '12%' }}
        />
        <Select
          placeholder="Bot User"
          options={botUserData}
          onChange={handleInputChange}
          value={botUserId}
          className="search-input"
          showSearch
          optionFilterProp="label"
          style={{ marginLeft: 10 }}
        />
        <Select
          showSearch
          placeholder="Search Username"
          value={searchTerm}
          onSearch={(value) => {
            setSearchTerm(value)
            setPage(0)
            setHasMore(true)
            fetchUsers(value, true)
          }}
          onBlur={() => {
            if (!userId) {
              setSearchTerm('') // Only clear if no user is selected
            }
          }}
          onPopupScroll={(e) => {
            const bottom =
              e.currentTarget.scrollHeight ===
              e.currentTarget.scrollTop + e.currentTarget.clientHeight
            if (bottom && hasMore && !loadingUsers) {
              fetchUsers(searchTerm, false)
            }
          }}
          onChange={(value) => {
            setUserId(value)
            setSearchTerm(
              userData.find((user) => user.value === value)?.label || ''
            )
          }}
          loading={loadingUsers}
          filterOption={false}
          notFoundContent={
            loadingUsers ? <Spin size="small" /> : 'No users found'
          }
          style={{ marginLeft: 10, width: '200px' }}
        >
          {userData.map((user) => (
            <Select.Option key={user.value} value={user.value}>
              {user.label}
            </Select.Option>
          ))}
        </Select>

        <div style={{ position: 'relative' }}>
          <Input.TextArea
            id="editTextArea"
            rows={4}
            placeholder="Write your Message here..."
            value={postContent}
            onChange={handleMessageChange}
            style={{ marginBottom: '16px' }}
            ref={textAreaRef}
          />
          <Button
            type="text"
            icon={<SmileOutlined />}
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 1,
            }}
          />
          {showEmojiPicker && (
            <div style={{ position: 'absolute', zIndex: 2, right: 0, top: 40 }}>
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </div>
          )}
        </div>
        <Upload
          beforeUpload={() => false}
          showUploadList={false}
          name="file"
          multiple={false}
          onChange={handleUploadChange}
          style={{ marginBottom: '16px' }}
        >
          <Button icon={<UploadOutlined />}>Attach File</Button>
        </Upload>
        <br></br>
        <div className="inline-form-item image-row">
          <div className="attachments-wrapper">
            {attachments.map((file, index) => (
              <div key={index} className="attachment-container">
                {file.type.startsWith('image/') ? (
                  <img
                    src={file.url}
                    className="card-image"
                    alt={file.name || 'Attachment'}
                    style={{
                      width: '100px',
                      height: 'auto',
                      objectFit: 'contain',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                    }}
                  />
                ) : (
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.name || 'Attachment'}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Spin>
  )
}

export default Message
