import { fetchUtil as fetch } from 'src/utils/fetch'
import { message } from 'antd'
import { getToken } from 'src/utils/accessToken'

interface AccessToken {
  user_id: string
}

export const users = async () => {
  try {
    const { data } = await fetch('/auth/allAdminUsers')
    return data
  } catch (e) {
    message.error('Get Audit Users error!')
  }
}

export const getAuditData = async (page, size, userId = '') => {
  try {
    const requestBody: {
      page: number
      size: number
      userId?: string
    } = {
      page,
      size,
    }
    if (userId !== '') {
      requestBody.userId = userId
    }
    const { data } = await fetch('/audit/search', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Audit Data Error!')
  }
}

export const saveAuditData = async (action, resourceName, resourceId) => {
  const { data } = await fetch('/audit', {
    method: 'POST',
    body: JSON.stringify({
      action: action,
      userId: getUserId(),
      resourceName: resourceName,
      resourceId: resourceId,
    }),
    headers: {
      role: 'ADMIN',
    },
  })
  return data
}

const getUserId = () => {
  const token = getToken()
  const decodedToken = parseJwt(token)
  return decodedToken.user_id
}

function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}
