import { message } from 'antd'
import { fetchUtil as fetch } from 'src/utils/fetch'
import { saveAuditData } from 'src/api/audit'

export const getTreasureHunter = async (page, size, name = '') => {
  try {
    const { data } = await fetch('/metaverse-rooms/treasure/search', {
      method: 'POST',
      body: JSON.stringify({
        size: size,
        page: page,
        name: name,
      }),
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Search Treasure Hunter Error !')
  }
}

export const saveEditTreasureHunter = async (
  id,
  title,
  desc,
  instruction,
  img,
  thumbImage,
  accessLevels,
  enabled
) => {
  try {
    const input = new FormData()
    input.append(
      'treasureEditDto',
      new Blob(
        [
          JSON.stringify({
            id: id,
            title: title,
            description: desc,
            enabled: enabled,
            instruction: instruction,
            accessLevels: accessLevels,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    input.append('image', img)
    input.append('thumbImage', thumbImage)

    const { data } = await fetch('/metaverse-rooms/treasure/edit', {
      method: 'POST',
      body: input,
      headers: {
        role: 'ADMIN',
      },
    })
    saveAuditData('EDIT', 'Treasure Hunter', id)
    message.success('Success Edit Treasure Hunter!')
    return 'success'
  } catch (e) {
    message.error('Error Edit Treasure Hunter!')
    return 'failed'
  }
}
