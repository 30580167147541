import React, { useState, useEffect } from 'react'
import { Button, Collapse, Input, Modal, Form, Divider, Spin } from 'antd'
import {
  EditOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { get, save, deleteReason } from 'src/api/abuse'
import './Abuse.css'

const { Panel } = Collapse
const { confirm } = Modal

const Abuse = () => {
  const [items, setItems] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const data = await get()
      setItems(data.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const showEditModal = (item) => {
    if (item === null) {
      item = { title: '', description: '' }
    }
    setCurrentItem(item)
    form.setFieldsValue(item)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form.validateFields().then((values) => {
      const updatedItem = { ...values, id: currentItem?.id }
      setIsModalVisible(false)
      setIsLoading(true)
      save(updatedItem)
        .then((response) => {
          if (response === 'success') {
            if (updatedItem.id !== undefined) {
              const updatedItems = items.map((item) =>
                item.id === currentItem.id ? updatedItem : item
              )
              setItems(updatedItems)
            } else {
              fetchData()
            }
          }
        })
        .finally(() => {
          setIsLoading(false)
          //setIsModalVisible(false)
        })
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleDelete = (item) => {
    setIsLoading(true)
    deleteReason(item)
      .then((response) => {
        if (response === 'success') {
          const updatedItems = items.filter((i) => i.id !== item.id)
          setItems(updatedItems)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const showDeleteConfirm = (item) => {
    confirm({
      title: `Are you sure you want to delete: ${item.title}?`,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(item)
      },
    })
  }

  const customPanelHeader = (item) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span style={{ flexGrow: 1 }}>{item.title}</span>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Button
          type="primary"
          size="small"
          icon={<EditOutlined />}
          onClick={() => showEditModal(item)}
          style={{ marginLeft: 8 }}
        />
        <Button
          type="primary"
          danger
          size="small"
          icon={<DeleteOutlined />}
          onClick={() => showDeleteConfirm(item)}
        />
      </div>
    </div>
  )

  return (
    <Spin spinning={isLoading}>
      <div>
        <Button onClick={() => showEditModal(null)} type="primary">
          <AppstoreAddOutlined />
          Add Reason
        </Button>
        <Divider style={{ margin: '10px 0' }} />
        <Collapse>
          {items.map((item) => (
            <Panel header={customPanelHeader(item)} key={item.id}>
              <div style={{ marginLeft: 24 }}>
                <p>{item.description}</p>
              </div>
            </Panel>
          ))}
        </Collapse>

        <Modal
          title="Edit Abuse Reason"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Spin>
  )
}

export default Abuse
